<template>
  <div class="">
    <el-tabs v-model="activeName">
      <el-tab-pane label="客户" name="0">
        <div class="settingTable">
          <table class="system_table">
            <thead>
              <tr>
                <th width="300px">参数名称</th>
                <th width="500px">参数设置</th>
                <th>配置说明</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(elem,index) in msgData_custoemr">
                <td>{{elem.name}}</td>
                <td>
                  <el-radio v-model="radio" :label="elemi.id" :key="index" v-for="(elemi,index) in elem.setting">{{elemi.name}}</el-radio>
                </td>
                <td>{{elem.explain}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="销售" name="1">销售</el-tab-pane>
      <el-tab-pane label="生产" name="2">生产</el-tab-pane>
      <el-tab-pane label="仓库" name="3">仓库</el-tab-pane>
      <el-tab-pane label="采购" name="4">采购</el-tab-pane>
      <el-tab-pane label="人事" name="5">人事</el-tab-pane>
      <el-tab-pane label="财务" name="6">财务</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  export default{
    name:'',
    data(){
      return{
        //测试用
        activeName:'',
        radio:'',
        msgData_custoemr:[
          {
            name:'未联系客户归入公海库',
            setting:[
              {
                id:0,
                name:'提前三天提醒',
              },
              {
                id:1,
                name:'不提醒',
              }
            ],
            explain:''
          },
          {
            name:'未成交客户加入公海库',
            setting:[
              {
                id:0,
                name:'提前三天提醒',
              },
              {
                id:1,
                name:'不提醒',
              }
            ],
            explain:''
          },
          {
            name:'成交客户未联系加入公海库',
            setting:[
              {
                id:0,
                name:'提前三天提醒',
              },
              {
                id:1,
                name:'不提醒',
              }
            ],
            explain:''
          },
          {
            name:'员工删除客户',
            setting:[
              {
                id:0,
                name:'提醒',
              },
              {
                id:1,
                name:'不提醒',
              }
            ],
            explain:''
          },
          {
            name:'邮件未回复',
            setting:[
              {
                id:0,
                name:'提醒',
              },
              {
                id:1,
                name:'不提醒',
              }
            ],
            explain:''
          },
        ],
      }
    },
    methods:{

    },
    components:{

    }
  }
</script>

<style>
</style>
