<template>
  <div class="systemItemIndex">
    <el-tabs tab-position="left" v-model="billingTabIndex" style="height: 100%;"  @tab-click="tabChange">
      <el-tab-pane label="客户编码" name="4">
        <customerRuleSetting v-if="billingTabIndex == 4"></customerRuleSetting>
      </el-tab-pane>
      <el-tab-pane label="货品编码" name="0">
        <varcodeRuleSetting v-if="billingTabIndex == 0"></varcodeRuleSetting>
      </el-tab-pane>
      <el-tab-pane label="单据模板" name="1">
        <billsModelSetting v-if="billingTabIndex == 1"></billsModelSetting>
      </el-tab-pane>
      <el-tab-pane label="消息配置" name="2">
        <messageConfigSetting v-if="billingTabIndex == 2"></messageConfigSetting>
      </el-tab-pane>
      <el-tab-pane label="菜单配置" name="3">
        <menuConfigSetting v-if="billingTabIndex == 3"></menuConfigSetting>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState
  } from 'vuex'
  // 导入组件
  import varcodeRuleSetting from './setting_varcodeRule.vue'; //条码规则
  import billsModelSetting from './setting_billsModel.vue'; //单据模板
  import messageConfigSetting from './setting_messageConfig.vue'; //消息配置
  import menuConfigSetting from './setting_menuConfig.vue'; //菜单配置
  import customerRuleSetting from './setting_customerRule.vue'; //客户编码

  export default {
    name: '',
    data() {
      return {
        // tab页下标
        billingTabIndex: '0',
      }
    },

    watch: {
      // 当选项卡下标发生改变时
      billingTabIndex(newVal) {
        this.SETTINGTABINDEX(newVal);
      },
      $route() {
        this.billingTabIndex = this.$route.query.id
      },
    },
    created() {
      //获取路由传入的id
      this.billingTabIndex = this.$route.query.id;
      this.tabChange({
        name: this.billingTabIndex
      })
    },
    computed: {
      ...mapState({
        BillingTabIndex: state => state.billing.BillingTabIndex, //货品编码下标
      })
    },
    methods: {
      ...mapMutations([
        'SETTINGTABINDEX', //将单据模板选项卡下标存入vuex
      ]),

      /* 改变路由 */
      tabChange(tab) {
        this.$router.push({
          path: this.$route.path,
          query: {
            id: tab.name
          }
        })
      },
    },
    components: {
      varcodeRuleSetting,
      billsModelSetting,
      messageConfigSetting,
      menuConfigSetting,
      customerRuleSetting
    }
  }
</script>
<style scoped lang="less">
  .systemItemIndex /deep/.el-tabs__content {
    overflow-y: hidden !important;
  }
</style>
