<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：编码规则页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview" id="varcodeTable">
    <!-- 表格 -->
    <div class="billingview_tablewrapper allCodeBox billing_table" v-if="isPower">
      <el-table :data="allSetData" height="100%" border style="width: 100%">
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateClassfiy(scope.row)"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="prefix" sortable label="编号前缀" width="120"></el-table-column>
        <el-table-column prop="centre" label="编号中段格式" width="120"></el-table-column>
        <el-table-column prop="extent" label="顺序号长度" width="120"></el-table-column>
        <el-table-column prop="separator" label="分隔符" width="80"></el-table-column>
        <el-table-column label="示例" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{getExample(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="分类设置" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="isClassifySet" :disabled="isDisableClassify" @change="isOpenClassifySet">
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>

    <div class="classfiy_tabs" v-if="isPower">
      <!-- 左边分类 -->
      <div class="left_classfiy scollStyle">
        <!-- 分类 -->
        <div class="class_fiy">
          <!-- 全部分类 -->
          <span class="classfiy_icon">
            <i class="el-icon-menu classifyAll" @click="getClassifyData(ProductClassfiy)">全部分类</i>
          </span>
          <!-- 树形分类 -->
          <el-tree :data="ProductClassfiy" :props="defaultProps" :expand-on-click-node="false" default-expand-all
            @current-change="getClassifyData" highlight-current>
            <span class="custom-tree-node" slot-scope="{node,ProductClassfiy}">
              <div class="classfiy_name">{{ node.label }}</div>
            </span>
          </el-tree>
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="right_product">
        <el-checkbox class="classifyFirstLevel" v-model="isFirstLevel" @change="getClassifyData(ProductClassfiy)">
          只加载一级分类</el-checkbox>
        <el-popover placement="right" width="500" trigger="hover">
          <div class="tips">
            <p>1.货品编码前缀为第一个,货品中段格式和顺序号长度为最后两个。</p>
            <p>2.货品属性,来源,品牌,供应商该处只用于占位,具体编码需在数据字典设置,生成时根据所选数据生成编码。</p>
            <p>3.仓库选择后会在编码中设置主仓编码,主仓编码需在数据字典设置。</p>
            <p>4.货品分类若当前为三级分类,则可选择三级分类,若为二级则可选择前两级分类。</p>
          </div>
          <i class="el-icon-info tipsIcon" slot="reference"></i>
        </el-popover>
        <!-- 货品分类表格 -->
        <div class="fixedHeight">
          <div class="billingview_tablewrapper classify_table billing_table" v-show="isClassifySet">
            <el-table :data="classifySetData" height="100%" border>
              <!-- 索引 -->
              <el-table-column type="index" fixed width="50"></el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作" fixed width="100">
                <template slot-scope="scope">
                  <i class="el-icon-edit-outline" @click="updateClassfiy(scope.row)"></i>
                </template>
              </el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="name" sortable label="货品分类名称" width="180"></el-table-column>
              <el-table-column prop="prefix" label="编号前缀" width="120"></el-table-column>
              <el-table-column prop="centre" label="编号中段格式" width="120"></el-table-column>
              <el-table-column prop="extent" label="顺序号长度" width="120"></el-table-column>
              <el-table-column prop="separator" label="分隔符" width="80" :formatter="formatterSeparator">
              </el-table-column>
              <el-table-column label="示例" min-width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{getExample(scope.row)}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>


    <!-- 编码规则修改弹框 -->
    <el-dialog title="修改" :visible.sync="show_updateBox" :before-close="cancle_update" @opened="initDrag" width="550px"
      append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :rules="varcode_rules" :model="curUpdateObj" ref="varcode_ruleform" label-width="120px" size="mini">
          <el-row>
            <el-col :span="24">
              <el-form-item label="编号前缀:" prop="prefix">
                <el-input v-model="curUpdateObj.prefix" placeholder="请选择前缀"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="自定义中段格式:" prop="">
                <el-checkbox v-model="curUpdateObj.isCustomCentre">是</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="curUpdateObj.isCustomCentre">
            <el-col :span="24">
              <el-form-item label="编号中段格式:" prop="custom">
                <el-input v-model="curUpdateObj.customeCentre" placeholder="请输入中段格式"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="!curUpdateObj.isCustomCentre">
            <el-col :span="24">
              <el-form-item label="编号中段格式:" prop="centre">
                <el-select v-model="curUpdateObj.centre" size="mini" placeholder="请选择中段格式" style="width: 100%;">
                  <el-option v-for="item in midpieceRule" :key="item.id" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="顺序号长度:" prop="extent">
                <el-input oninput="this.value=this.value.replace(/[^0-9]/g,'')" v-model="curUpdateObj.extent"
                  placeholder="请输入顺序号长度">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="分隔符:" prop="separator">
                <el-select v-model="curUpdateObj.separator" default-first-option placeholder="请选择编码分隔符"
                  style="width: 100%;">
                  <el-option v-for="(item,index) in separatorData" :key="item.id" :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="顺序号规则:" prop="rule">
                <el-select v-model="curUpdateObj.rule" style="width: 100%;">
                  <el-option v-for="(item,index) in orderRule" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="billing_table" style="width: 100%;overflow: auto;">
            <el-col :span="24">
              <el-table :data="tableData" height="100%" border style="width: 100%" id="code_info_table">
                <!-- 拖拽图标 -->
                <el-table-column width="35">
                  <template slot-scope="scope">
                    <i class="el-icon-s-grid my-handle"></i>
                  </template>
                </el-table-column>
                <!-- 索引 -->
                <el-table-column type="index" width="35"></el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="name" label="名称" width="100"></el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" min-width="200">
                  <template slot-scope="scope">
                    <el-checkbox-group v-model="scope.row.classify"
                      v-if="scope.row.id==5 && scope.row.is_classfiy_set != 2">
                      <el-checkbox v-for="item in rowClassifyData" :key="item.id" :label="item.level">{{item.name}}
                      </el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox v-else v-model="scope.row.is_enable">启用</el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="curUpdateObj.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancle_update">取 消</el-button>
        <el-button size="small" type="primary" @click="updateClassifySet">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/individuation'
  //引入拖动排序
  import Sortable from "sortablejs";
  export default {
    name: 'setting_varcodeRule',
    data() {
      return {
        isPower: false, //是否有查询权限
        //当前界面相关数据
        allSetData: [], //统一设置表格数据
        classifySetData: [], //分类设置表格数据
        classifyAllData: [], //分类设置表格数据
        curUpdateObj: {}, //当前正在修改的数据
        varcode_rules:[],//表单验证规则
        isClassifySet: false, //是否分类设置
        isDisableClassify: false, //是否禁用分类设置(初始化时用)
        rowClassifyData: [], //编辑时的分类信息
        sourceData: {}, //编辑前数据

        //控制开关
        show_updateBox: false, //控制显示修改弹框

        //树形列表配置
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        isFirstLevel: false, //选择是否只加载一级菜单

        tableData: [{
          id: 0,
          name: '货品属性',
          is_enable: false
        }, {
          id: 1,
          name: '货品来源',
          is_enable: false
        }, {
          id: 2,
          name: '货品品牌',
          is_enable: false
        }, {
          id: 3,
          name: '仓库',
          is_enable: false
        }, {
          id: 4,
          name: '供应商',
          is_enable: false
        }, {
          id: 5,
          name: '货品分类',
          is_enable: false,
          classify: []
        }]
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
      // 判断vuex中是否含有数据
      this.judjeProductType()
    },
    mounted() {},
    computed: {
      ...mapState({
        ProductClassfiy: state => state.system.ProductClassfiy, //货品分类数据
        BillingTabIndex: state => state.billing.BillingTabIndex, //货品编码下标
        separatorData: state => state.system.separatorData, //系统定义的可使用分隔符
        orderRule: state => state.system.orderRule, //系统定义的可使用顺序号规则
        midpieceRule: state => state.system.midpieceRule, //系统定义的可使用中段格式
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    watch: {
      //监听tab页面切换
      BillingTabIndex: {
        handler(newVal) {
          //判断是否为货品编码设置界面
          if (newVal == 0) {
            this.findCommodityInfo(0)
          }
        },
        immediate: true,
      },
    },
    methods: {
      ...mapMutations([
        'BILLING_VARCODERULE',
      ]),
      ...mapActions([
        'getProductClassfiy', //获取货品分类数据
        'getUserPowerInfo', //获取用户操作权限
      ]),

      // 判断vuex中是否含有数据
      async judjeProductType() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 5, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 5, 1, '查询')) {
            return
          }
          this.isPower = true
        }
        // vuex中没有数据,从服务器请求
        if (this.ProductClassfiy.length == 0) {
          await this.getProductClassfiy();
        }
        //默认获取一级分类
        this.getClassifyData(this.ProductClassfiy);
      },

      /* 获取表格数据 */
      getClassifyData(data) {
        this.classifySetData = []; //获取前先清空
        if (this.isFirstLevel) { //只加载一级菜单
          //判断是一级还是子级
          if (!!data.length) {
            this.classifySetData = data;
          } else {
            if (data.hasOwnProperty('children') && !!data.children) {
              this.classifySetData = data.children;
            }
          }
        } else { //加载全部
          let classifyData //定义分类数组
          if (data.length > 1) { //当数组长度大于1时(即选择全部分类时)
            classifyData = data
          } else if (data.hasOwnProperty('children') && !!data.children) { //当选择的是二级或二级以下分类时
            classifyData = data.children
          }
          if (classifyData) {
            classifyData.forEach(itemI => {
              this.classifySetData.push(itemI) //存入一级分类
              if (itemI.hasOwnProperty('children') && !!itemI.children) {
                itemI.children.forEach(itemJ => {
                  this.classifySetData.push(itemJ) //存入二级分类
                  if (itemJ.hasOwnProperty('children') && !!itemJ.children) {
                    itemJ.children.forEach(itemL => {
                      this.classifySetData.push(itemL) //存入三级分类
                    })
                  }
                })
              }
            })
          }
        }
        this.getCommodityInfo(this.classifySetData) //处理分类信息
      },

      /* 处理分类信息 */
      getCommodityInfo(data, type) {
        //循环前清空数组
        this.classifySetData = [];
        //循环处理界面显示数据
        data.forEach((item, index) => {
          if (item.format_str) {
            // 分割编号格式
            let splitArr = item.format_str.split("@@@");
            item.prefix = splitArr[0]; //编号前缀
            item.centre = splitArr[1]; //中段格式
            item.extent = splitArr[2]; //顺序号长度
            item.rule = splitArr[3]; //顺序号规则
          }
          //获取通用编码规则
          if (item.is_classfiy_set == 2) {
            this.allSetData.push(item)
          } else { //获取分类编码规则
            if (type != 1) {
              this.classifySetData.push(item)
            }
          }
        })

        //获取是否分类设置
        if (this.classifySetData.length == 0) { //无分类数据
          this.isDisableClassify = true; //禁用
          this.isClassifySet = false; //不勾选
        } else { //有分类数据
          this.isDisableClassify = false; //不禁用
          //判断是否分类设置
          if (this.classifySetData[0].is_classfiy_set == 0) { //未分类设置
            this.isClassifySet = false;
          } else if (this.classifySetData[0].is_classfiy_set == 1) { //分类设置
            this.isClassifySet = true;
          }
        }

        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 4, 4, '修改', 0)) {
          return
        }
        this.isDisableClassify = false;
      },

      /* 查询所有货品编码 type(0:不需要提示信息  1:需要提示信息),由于该界面只有修改操作,所以只有一种提示信息*/
      async findCommodityInfo(type) {
        await this.getProductClassfiy(); //再次请求一次数据 刷新this.ProductClassfiy
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#varcodeTable", 3);
        //获取数据
        api.findAllProductTypeNoChild().then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.allSetData = [];
            this.getCommodityInfo(res.data, 1) //处理分类信息
            this.classifyAllData = JSON.parse(JSON.stringify(this.ProductClassfiy))
            this.getClassifyData(this.ProductClassfiy);
            // console.log(this.ProductClassfiy)
            //查询完成后的提示信息
            let message = "";
            if (type == 1) { //修改后查询
              this.$message({
                type: 'success',
                message: "分类设置修改成功!",
                duration: this.elDuration
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 排序 */
      compare(property) {
        return (a, b) => {
          var value1 = a[property];
          var value2 = b[property];
          return value1 - value2;
        }
      },

      /*显示编码修改弹框*/
      updateClassfiy(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 5, 4, '修改')) {
          return
        }
        //树形转非树形
        let varcodeData = this.commonJsExtend.toNoTree(this.classifyAllData, [])
        let classifyData = []
        varcodeData.forEach(itemI => {
          if (itemI.id == data.id) {
            itemI.level = '5.3'
            classifyData.push(itemI)
          }
          if (itemI.id == data.parent_id) {
            itemI.level = '5.2'
            classifyData.push(itemI)
            varcodeData.forEach(itemJ => {
              if (itemI.parent_id == itemJ.id) {
                itemJ.level = '5.1'
                classifyData.push(itemJ)
              }
            })
          }
        })
        this.rowClassifyData = classifyData.sort(this.compare('level'))
        //获取顺序号规则
        // let rule = this.commonJsExtend.getDictMatchingData(data.rule, this.orderRule);
        //获取当前编辑的数据
        this.curUpdateObj = {
          id: data.id, //id
          is_classfiy_set: data.is_classfiy_set, //分类
          prefix: data.prefix, //编号前缀
          centre: data.is_custom == 0 ? data.centre : '', //中段格式(选择)
          customeCentre: data.is_custom == 1 ? data.centre : '', //中段格式(自定义)
          isCustomCentre: data.is_custom == 1 ? true : false, //是否自定义中段格式
          extent: data.extent, //顺序号长度
          separator: data.separator, //分隔符
          rule: data.rule - '', //顺序号规则名称
          remark: data.remark, //说明
        };
        let tableArr = []
        if (data.format_str) {
          tableArr = data.format_str.split('@@@') //字符串转数组
          tableArr = tableArr.slice(4, tableArr.length) //切除前缀中段格式顺序号长度顺序号规则
        }
        let templateDate = []
        Object.assign(this.$data.tableData, this.$options.data().tableData);
        tableArr.forEach(itemI => {
          this.tableData.forEach(itemJ => {
            if (itemI == itemJ.id) {
              itemJ.is_enable = true //改为启用
              templateDate.push(itemJ) //将元素存入数组中
            } else if (itemI.indexOf(5) != -1) {
              if (itemI) {
                if (data.is_classfiy_set == 2) { //通用时
                  itemJ.is_enable = true //改为启用
                } else {
                  itemJ.classify = itemI.split('-')
                }
              }
            }
          })
        })
        //过滤出没有启用的数据
        let list = this.tableData.filter(item => {
          this.$set(item, 'is_classfiy_set', data.is_classfiy_set)
          return templateDate.indexOf(item) === -1
        });
        //将没有启用的数据插入表格数组后面
        list.forEach(item => {
          this.$set(item, 'is_classfiy_set', data.is_classfiy_set)
          templateDate.push(item)
        })
        this.tableData = templateDate //赋值
        this.sourceData = JSON.parse(JSON.stringify(this.curUpdateObj)) //存储编辑前数据
        //显示弹框
        this.show_updateBox = true;
      },

      /* 提交修改后的货品分类数据 */
      updateClassifySet() {
        //验证表单
        this.$refs['varcode_ruleform'].validate(valid => {
          if (valid) {
            //获取编码规则字符串
            let format_arr = [];
            let classifyStr = '';
            format_arr.push(this.curUpdateObj.prefix); //编号前缀
            //判断中段格式是否为自定义
            if (this.curUpdateObj.isCustomCentre) {
              format_arr.push(this.curUpdateObj.customeCentre); //中段格式
            } else {
              format_arr.push(this.curUpdateObj.centre); //中段格式
            }
            format_arr.push(this.curUpdateObj.extent); //顺序号长度
            format_arr.push(this.curUpdateObj.rule); //顺序号规则
            this.tableData.forEach(item => {
              if (item.is_enable && item.id != 5) {
                format_arr.push(item.id); //其他货品信息
              } else if (item.id == 5) { //分类
                if (item.is_enable) {
                  classifyStr = '@@@5'
                } else if (item.classify.length != 0) {
                  classifyStr = '@@@' + item.classify.join('-')
                }
              }
            })
            let format_str = format_arr.join("@@@") + classifyStr;
            let logContent = '' //日志内容
            let objFiled = { //定义字典
              centre: '编号中段格式',
              customeCentre: '编号中段格式',
              extent: '顺序号长度',
              isCustomCentre: '自定义中段格式',
              prefix: '编号前缀',
              rule: '顺序号规则',
              separator: '分隔符',
            }
            let date = [{ //转换年月日字典
              4: '年',
              3: '月',
              1: '日'
            }]
            let keys = Object.keys(objFiled) //获取字段名
            keys.forEach(item => {
              if (this.sourceData[item] != this.curUpdateObj[item]) {
                if (item == 'isCustomCentre') { //自定义中段格式
                  logContent += '更改了【货品编码】中的【' + objFiled[item] + '】,更改前:' + (this.sourceData[item] ? '是' :
                    '否') + ",更改后:" + (this.curUpdateObj[item] ? '是' : '否') + ';' //拼接日志信息
                } else if (item == 'rule') { //顺序号规则
                  logContent += '更改了【货品编码】中的【' + objFiled[item] + '】,更改前:' + date[0][this.sourceData[
                      item]] +
                    ",更改后:" +
                    date[0][this.curUpdateObj[item]] + ';' //拼接日志信息
                } else {
                  logContent += '更改了【货品编码】中的【' + objFiled[item] + '】,更改前:' + this.sourceData[item] +
                    ",更改后:" +
                    this.curUpdateObj[item] + ';' //拼接日志信息
                }
              }
            })
            //定义修改的参数
            let data = {
              id: this.curUpdateObj.id, //修改的id
              separator: this.curUpdateObj.separator, //分隔符
              format_str: format_str, //编码规则
              is_custom: this.curUpdateObj.isCustomCentre ? 1 : 0, //是否自定义中段格式
              is_classfiy_set: this.curUpdateObj.is_classfiy_set, //类型
              logContent //日志
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#varcodeTable", 0);
            api.updateProductCodeTemp(data).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //关闭弹框
                this.show_updateBox = false;
                //获取数据
                this.findCommodityInfo(1);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        })
      },

      /* 示例拼接 */
      getExample(data) {
        let random = ''
        //生成随机数
        for (var i = 0; i < data.extent; i++) {
          random += Math.floor(Math.random() * 10)
        }
        if (!data.prefix) return
        let classifyNameData = []
        let tableArr = []
        if (data.format_str) {
          tableArr = data.format_str.split('@@@') //字符串转数组
          tableArr = tableArr.slice(4, tableArr.length) //切除前缀中段格式顺序号长度顺序号规则
        }
        tableArr.forEach(itemI => {
          this.tableData.forEach(itemJ => {
            if (itemI == itemJ.id) {
              classifyNameData.push(itemJ.name) //获取分类名称
            } else if (itemI.indexOf(5) != -1 && itemJ.id == 5) {
              if (itemI) {
                itemI.split('-').forEach(itemK => {
                  if (itemK == '5.1') {
                    classifyNameData.push('一级菜单') //一级菜单
                  } else if (itemK == '5.2') {
                    classifyNameData.push('二级菜单') //二级菜单
                  } else if (itemK == '5.3') {
                    classifyNameData.push('三级菜单') //三级菜单
                  }
                })
              }
            }
          })
        })
        let classifyNameStr = classifyNameData.join(data.separator) //分类名称转字符串
        if (classifyNameStr) {
          classifyNameStr = data.separator + classifyNameStr //拼接
        }
        if (data.is_custom == 0) { //非自定义中段格式
          return data.prefix + classifyNameStr + data.separator + this.commonJsExtend.transformDate(data.centre) + data
            .separator +
            random //拼接示例
        } else if (data.is_custom == 1) { //自定义中段格式
          return data.prefix + data.separator + data.centre + data.separator + random //拼接示例
        }
      },

      /* 是否开启分类设置 */
      isOpenClassifySet() {
        let data = {
          isClassfiySet: this.isClassifySet ? 1 : 0,
          logContent: this.isClassifySet ? '开启了通用货品编码的分类设置' : '关闭了通用货品编码的分类设置'
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#varcodeTable", 4, '分类设置修改中,请稍候...');
        //发送请求
        api.updateIsClassfiySet(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.findCommodityInfo(1);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 取消修改 */
      cancle_update() {
        this.show_updateBox = false;
        //重置表单验证规则
        this.$refs['varcode_ruleform'].clearValidate();
      },

      /* 空格与无需 */
      formatterSeparator(row, column, cellValue, index) {
        if (!Boolean(cellValue)) {
          return '无'
        } else if (cellValue == " ") {
          return '空格'
        } else {
          return cellValue;
        }
      },

      /* 初始化表格行拖动功能*/
      initDrag() {
        const el = document.querySelectorAll("#code_info_table>.el-table__body-wrapper > table > tbody")[0];
        Sortable.create(el, {
          disabled: false, //启用拖拽
          sort: true, // boolean 定义是否列表单元是否可以在列表容器内进行拖拽排序
          animation: 150, // 定义排序动画的时间
          handle: ".my-handle", //拖动的手柄
          // 结束拖拽
          onEnd: (e) => {
            // 获取排序之后的data数据
            this.tableData.splice(e.newIndex, 0, this.tableData.splice(e.oldIndex, 1)[0])
            var newArray = this.tableData.slice(0)
            this.tableData = []
            this.$nextTick(() => {
              this.tableData = newArray
            })
          },
        })
      },
      /* 表单验证*/
      initRules() {
        //第一个修改框验证规则
        this.varcode_rules = {
          prefix: [{ //前缀
            required: true, //是否必填
            message: '请输入编号前缀', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, { //规则2
            min: 1, //最小长度
            max: 10, //最大长度
            message: '长度在 1 到 10 个字符', //该规则错误提示信息
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }],
          /* centre: [{ //中段格式
             required: true,
             message: '请至少选择一个中段格式',
             trigger: 'change'
           }], */
          rule: [{ //顺序号规则
            required: true,
            message: '请选择一个顺序号规则',
            trigger: 'blur'
          }, ],
          extent: [{ //顺序号长度
              required: true, //是否必填
              message: '请输入顺序号长度', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 1, //最大长度
              message: '长度只能为个位数', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },
    },
    filters: {}
  }
</script>

<style lang="less" scoped>
  //表格固定高度
  .fixedHeight {
    height: 100%;
  }

  //整体设置分类
  .allCodeBox {
    height: 100px;
  }

  //按分类设置分类
  .classify_table {
    min-height: 630px !important;
    margin-top: 10px;
  }

  //弹框外框样式
  .financeDialog {
    padding: 0 20px 0 0;
    // border: 1px solid black;
  }
</style>

<style lang="less" scoped>
  .tips {
    padding: 10px;
  }

  .tipsIcon {
    color: #409EFF;
    padding: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  .classfiy_tabs {
    height: 77.55vh;
    display: flex;
    border: solid 2px #eeeeee;

    /* 左边分类 */
    .left_classfiy {
      height: 77.8vh;
      min-width: 220px;
      overflow: auto;
      padding-right: 7px;
      border-right: 2px #eeeeee solid;

      .class_fiy {
        padding: 10px;
        // border: 1px solid black;

        .classfiy_icon {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-size: 14px;
        }

        .custom-tree-node {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            display: none;
          }

          // border: 1px solid red;
          .classfiy_name {
            line-height: 28px;
          }
        }

        .custom-tree-node:hover {
          button {
            font-size: 16px;
            display: block;
          }
        }
      }
    }

    /* 右边内容 */
    .right_product {
      width: 1470px !important;
      height: 82.9vh;
      margin: 0 5px;
      flex: 2;

      .classifyFirstLevel {
        padding: 10px 0 0 10px;
      }

      // border: 1px solid black;
      .top_checkbox {
        margin-bottom: 10px;
        // border: 1px solid black;
      }

      .classfiy_table {
        height: 81vh;
        // border: red solid 1px;
      }
    }
  }
</style>
