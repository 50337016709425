// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：系统.客户配置相关请求
// 开始时间：2021-05-07
// 开发人员：万攀
// 最后修改：2021-05-07
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default{
  getAllcompanyInfo(){//获取企业所有参数值
    return request({
      method: 'POST',
      url: '/crm/getCompanyParameters'
    })
  },
  getExcludeInfo(){//获取排除信息
    return request({
      method: 'POST',
      url: '/crm/getExclude'
    })
  },
  getFollowupSet(){//获取配置信息
    return request({
      method: 'POST',
      url: '/crm/getFollowupSet'
    })
  },
  updateSysParame(data){//修改客户配置,基本配置
    return request({
      method: 'POST',
      url: '/crm/updateSysParame',
      data
    })
  },
  updateFollowupSet(data){//修改配置信息
    return request({
      method: 'POST',
      url: '/crm/addOrEditSet',
      data
    })
  },
  updateExcludeInfo(data){//修改排除信息
    return request({
      method: 'POST',
      url: '/crm/addOrEditExclude',
      data
    })
  },
  addMarketing(data){//指定运营人员
    return request({
      method:'POST',
      url:'/user/updateUserYunying',
      data
    })
  },
  addClassfiyYunXiao(data){//只营销客户分类
    return request({
      method:'POST',
      url:'/crm/addYingxiao',
      data
    })
  },
  updateProductionParame(data){//生产参数配置
    return request({
      method:'POST',
      url:'/productionParam/updateSysParame',
      data
    })
  },
  updateProductParame(data){//货品参数配置
    return request({
      method:'POST',
      url:'/productParam/updateSysParame',
      data
    })
  },
  getcompanyparametersmap(){//获取当前登录企业所有控制参数
    return request({
      method:'POST',
      url:'/crm/getcompanyparametersmap',
    })
  },
	updateUploadParame(data){//上传参数配置
	  return request({
	    method:'POST',
	    url:'/uploadParam/updateSysParame',
	    data
	  })
	},
	updateFinanceSysParame(data){//上传参数配置
	  return request({
	    method:'POST',
	    url:'/financeParam/updateFinanceSysParame',
	    data
	  })
	},
  updateSysParametersValue(data){//修改企业级控制参数
    return request({
      method:'POST',
      url:'/system/paramatersValue/updateSysParametersValue',
      data
    })
  },
  findSysParametersValueById(data){//修改企业级控制参数
    return request({
      method:'POST',
      url:'/system/paramatersValue/findSysParametersValueById',
      data
    })
  },
  
  // 系统功能模块权限相关
  findModelControlByModelCode(data){//根据模块code值查询模块服务是否到期
    return request({
      method:'POST',
      url:'/system/control/findModelControlByModelCode',
      data
    })
  },
}
