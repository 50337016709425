<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：个性化配置-菜单配置
开始时间：2021-08-03
开发人员：康泽鑫
最后修改：2021-08-03
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="individuation_menu" id="individuation_menu">
    <!-- 左边树形菜单栏 -->
    <div class="left_menu scollStyle" v-if="isPower">
      <!-- 全部 -->
      <span class="menu_icon">
        <i class="el-icon-menu menu_all" @click="getMenuData(0)">全部</i>
        <el-button type="text" size="mini" class="add_btn">
          <i class="el-icon-plus" @click="showDialog(0)"></i>
        </el-button>
      </span>
      <!-- 树形 -->
      <el-tree :data="menuData" :props="defaultProps" :expand-on-click-node="false" @current-change="getMenuData"
        node-key="id" :default-expanded-keys="defExpanded" highlight-current>
        <span class="custom-tree-node" slot-scope="{node,data}">
          <span>{{ node.label }}</span>
          <span>
            <el-button type="text" size="mini" class="add_btn">
              <!-- 新增 -->
              <i class="el-icon-plus" @click="showDialog(0,data)" v-show="node.level!=3"></i>
              <!-- 单据快捷新增 -->
              <el-popover placement="right-start" width="180" trigger="hover" @show="getBillsData(data)">
                <el-table :data="billsData" @select="fastAddMenu" :show-header="false" ref="billsDataTable" size="mini"
                  max-height="300">
                  <el-table-column type="selection" width="42"></el-table-column>
                  <el-table-column property="bills_type" label="单据名称"></el-table-column>
                </el-table>
                <i class="el-icon-more" slot="reference" v-show="fastBillsIds.indexOf(data.id)!=-1"></i>
              </el-popover>
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <!-- 右边表格操作 -->
    <div class="right_menu">
      <div class="menu_table billing_table">
        <el-table :data="tableData" height="100%" border style="width: 100%" id="menu_config_table">
          <!-- 拖拽图标 -->
          <el-table-column width="35">
            <template slot-scope="scope">
              <i class="el-icon-s-grid my-handle"></i>
            </template>
          </el-table-column>
          <!-- 索引 -->
          <el-table-column type="index" width="35"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <i class="el-icon-delete icon" @click="del(scope.row)" title="删除" v-if="scope.row.isSystem!=1"></i>
              <i class="el-icon-edit-outline icon" @click="showDialog(1,scope.row)" title="修改"></i>
            </template>
          </el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="name" sortable label="全称" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="shortName" label="简称" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.shortName || '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <el-checkbox :value="scope.row.isEnable == 1" disabled>显示</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="路由" width="400" show-overflow-tooltip></el-table-column>
          <el-table-column prop="updateTime" label="修改时间" width="200"></el-table-column>
          <el-table-column prop="ramrk" label="备注"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 新增编辑弹窗 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="400px" :before-close="closeDialog" append-to-body>
      <el-form label-position="right" label-width="95px" :model="menuConfig" :rules="rules" ref="menuConfigForm"
        size="mini" class="dialog">
        <el-form-item label="全称：" prop="name">
          <el-input v-model="menuConfig.name" placeholder="请输入全称"></el-input>
        </el-form-item>
        <el-form-item label="简称：" prop="shortName">
          <el-input v-model="menuConfig.shortName" placeholder="请输入简称"></el-input>
        </el-form-item>
        <el-form-item label="链接类型：">
          <el-radio-group v-model="menuConfig.urlType" class="radioGroup">
            <el-radio :label="0">内部链接</el-radio>
            <el-radio :label="1">外部链接</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="链接地址：" prop="url">
          <el-input v-model="menuConfig.url" placeholder="请输入链接地址"></el-input>
        </el-form-item>
        <el-form-item label="打开方式：">
          <el-radio-group v-model="menuConfig.openType" class="radioGroup">
            <el-radio :label="0">当前页面打开</el-radio>
            <el-radio :label="1">新标签页打开</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态：">
          <el-checkbox v-model="menuConfig.isEnable">显示</el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="mini">取 消</el-button>
        <el-button type="primary" @click="makeSure(0)" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //引入接口
  import api from '@/network/system/individuation'
  //引入拖动排序
  import Sortable from "sortablejs";
  export default {
    name: "setting_menuConfig",
    data() {
      return {
        isPower: false, //是否有查询权限

        //控制开关
        dialogVisible: false, //新增修改弹框
        curentClassify: 0, //当前查询的分类(默认全部)

        //新增修改弹框
        title: '', //标题
        parentId: -1, //父级id
        operateType: '', //操作方式(0:新增 1:修改)

        //树形列表配置
        defaultProps: {
          children: 'child',
          label: 'name'
        },
        defExpanded: [], //默认展开

        fastBillsIds: [285, 330, 313, 337, 403], //需要显示快捷单据新增的菜单id
        billsParentIds: '', //快捷新增单据父级id
        billsUrl: '', //快捷新增单据url

        menuData: [], //侧边栏数据
        tableData: [], //表格数据
        billsData: [], //快捷单据数据

        menuConfig: {}, //新增修改表单数据
        sourceData: {}, //编辑前数据

        //表单校验
        rules: {
          name: [{
            required: true,
            message: '全称不能为空',
            trigger: 'blur'
          }]
        }
      }
    },
    computed: {
      ...mapState({
        userinfo: state => state.userinfo, //当前用户信息
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        BillingTabIndex: state => state.billing.BillingTabIndex, //模板下标
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    created() {},
    mounted() {
      this.initDrag(); //初始化排序
      this.resetData() //初始化数据
      this.initVuexData() //初始化Vuex数据
    },
    watch: {
      //监听tab页面切换
      BillingTabIndex: {
        handler(newVal) {
          //判断是否为货品编码设置界面
          if (newVal == 3) {
            //获取菜单数据
            this.getMenuData(0);
          }
        },
        immediate: true,
      },
    },
    methods: {
      ...mapMutations([
        'MENU_CONFIG', //菜单配置
      ]),
      ...mapActions([
        'getelectronicTemplate', //获取所有单据类型
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化Vuex数据 */
      initVuexData() {
        //判断单据类型数据是否存在
        if (this.billTemplateData.length == 0) {
          this.getelectronicTemplate();
        }
      },

      /* 获取菜单配置数据 data=> 0:全部 */
      async getMenuData(data, node) {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 10, 1, '查询')) {
            this.tableData = []
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 10, 1, '查询')) {
            this.tableData = []
            return
          }
          this.isPower = true
        }
        if (data != 0) {
          this.defExpanded = [data.id] //默认展开
        } else {
          this.defExpanded = []
        }
        //将当前查询的分类存入
        this.curentClassify = data;
        //获取前先清空
        this.tableData = [];
        //加载loding框
        this.loading = this.commonJsExtend.customLoading("#individuation_menu", 4, '菜单列表加载中,请稍候...');
        //发送请求
        api.findAllMenuConfig().then(res => {
          this.loading.close();
          if (res.code == 200) {
            //更新vuex数据
            this.MENU_CONFIG(res.data);
            this.menuData = res.data //侧边栏数据
            //判断类型
            if (data == 0) { //全部
              this.tableData = res.data //表格数据
              // console.log(this.tableData)
            } else { //分类
              this.menuData.forEach(itemI => {
                if (itemI.id == data.id) { //匹配对应数据
                  if (itemI.hasOwnProperty('child') && !!itemI.child) {
                    this.tableData = itemI.child; //子菜单列表
                  }
                } else {
                  if (itemI.child) { //当存在子级时继续匹配子级
                    itemI.child.forEach(itemJ => {
                      if (itemJ.id == data.id) { //匹配对应数据
                        if (itemJ.hasOwnProperty('child') && !!itemJ.child) {
                          this.tableData = itemJ.child; //子菜单列表
                        }
                      }
                    })
                  }
                }
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 新增修改菜单配置 */
      makeSure(data) {
        if (!data) {
          let url = ''
          let paramVal = ''
          if (this.menuConfig.url) {
            if (this.menuConfig.url.indexOf('?') != -1) {
              //取出url
              url = this.menuConfig.url.split('?')[0]
              //转换成 orderType:0@@@orderBillCode:DOC-S-001 格式
              paramVal = this.menuConfig.url.split('?')[1].replace(/=/g, ':').replace(/&/g, '@@@')
            } else {
              url = this.menuConfig.url
            }
          }
          data = {
            name: this.menuConfig.name, //全称
            shortName: this.menuConfig.shortName, //简称
            url: this.menuConfig.urlType == 1 ? this.menuConfig.url : url, //链接地址
            paramVal: this.menuConfig.urlType == 0 ? paramVal : '', //参数
            isEnable: this.menuConfig.isEnable ? 1 : 0, //状态
            openType: this.menuConfig.openType, //打开方式
            urlType: this.menuConfig.urlType, //链接类型
            parentId: this.parentId //父级id
          }
        }
        //发送请求
        if (this.operateType == 0) { //新增时
          api.insertSysMenuConfig(data).then(res => {
            if (res.code == 200) {
              //获取菜单配置数据
              this.getMenuData(this.curentClassify)
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else { //修改时
          // 日志
          let logContent = ""
          let objFiled = {
            name: '全称',
            shortName: '简称',
            url: '链接地址',
            isEnable: '状态',
            openType: '打开方式',
            urlType: '链接类型',
          }
          let keys = Object.keys(objFiled) //获取字段名
          keys.forEach(item => {
            if (this.menuConfig[item] != this.sourceData[item]) { //匹配出修改的字段
              logContent += '更改了【菜单配置】中的【' + objFiled[item] + '】,更改前:'
              if (item == 'urlType') { //链接类型
                logContent += (this.sourceData[item] == 0 ? '内部链接' : '外部链接') + ",更改后:" + (this.menuConfig[item] ==
                  0 ?
                  '内部链接' : '外部链接') + ';' //拼接日志信息
              } else if (item == 'openType') { //打开方式
                logContent += (this.sourceData[item] == 0 ? '当前页面打开' : '新标签页打开') + ",更改后:" + (this.menuConfig[
                    item] ==
                  0 ? '当前页面打开' : '新标签页打开') + ';' //拼接日志信息
              } else if (item == 'isEnable') { //状态
                logContent += (this.sourceData[item] == 0 ? '不显示' : '显示') + ",更改后:" + (this.menuConfig[item] == 0 ?
                  '不显示' : '显示') + ';' //拼接日志信息
              } else {
                logContent += this.sourceData[item] + ",更改后:" + this.menuConfig[item] + ';' //拼接日志信息
              }
            }
          })
          data.id = this.menuConfig.id //需修改数据id
          data.logContent = logContent //日志
          api.updateSysMenuConfig(data).then(res => {
            if (res.code == 200) {
              //获取菜单配置数据
              this.getMenuData(this.curentClassify)
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
        this.closeDialog() //关闭弹窗
      },

      /* 删除菜单 */
      del(row) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 10, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该菜单吗?!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            id: row.id, //id
            name: row.name //名称
          }
          api.deleteSysMenuConfig(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '菜单已删除!',
                duration: this.elDuration
              })
              //获取菜单配置数据
              this.getMenuData(this.curentClassify)
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        })
      },

      /* 获取快捷单据数据 */
      getBillsData(data) {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 10, 2, '新增')) {
          return
        }
        this.billsParentIds = data.id
        let billsUrls = [{
          url: '/sell_view', //url
          paramVal: 'orderType:@@@orderBillCode:' //参数
        }, {
          url: '/purchase_view', //url
          paramVal: 'purchaseType:@@@purchaseBillCode:' //参数
        }, {
          url: '/productionplan_view', //url
          paramVal: 'productType:@@@productBillCode:' //参数
        }, {
          url: '/warehouse_view', //url
          paramVal: 'inventoryType:@@@inventoryBillCode:' //参数
        },{
          url: '/expense_view', //url
          paramVal: 'expenseType:@@@expenseCode:' //参数
        }] //定义路径数组
        let typeId = this.fastBillsIds.indexOf(data.id) + 1 //需要显示快捷单据新增的菜单type
        this.billsUrl = billsUrls[typeId - 1]
        // console.log(this.billTemplateData)
        let billTemplateData = this.billTemplateData.filter(item => item.temp_id == typeId) //筛选对应单据
        this.billsData = [] //赋值前先清空
        let paramVals = [] //参数数组
        let codes = data.child.map(item => {
          if (item.paramVal) {
            if (item.paramVal.indexOf("@@@") != -1) {
              return item.paramVal.split('@@@')[1].split(':')[1]
            } else {
              paramVals.push(item.paramVal)
            }
          }
        }).join(',').split(','); //筛选出已有单据存入数组中
        codes = codes.concat(paramVals) //数组合并
        codes = codes.filter(item => { //去除数组中的空元素
          return item && item.trim();
        });
        this.billsData = billTemplateData[0].children.filter(item => codes.indexOf(item.code) == -1) //获取还未添加的单据菜单
      },

      /* 快捷新增菜单 */
      async fastAddMenu(data, row) {
        let param = this.billsUrl.paramVal //获取参数key值
        let paramVal = ''
        if (param) { //拼接参数值
          paramVal = param.split('@@@')[0] + row.type_classify
          if (param.split('@@@')[1]) {
            paramVal += '@@@' + param.split('@@@')[1] + row.code
          }
        }
        let menuData = {
          name: row.bills_type, //全称
          shortName: '', //简称
          url: this.billsUrl.url, //链接地址
          paramVal, //参数
          isEnable: 1, //状态
          openType: 0, //打开方式
          urlType: 0, //链接类型
          parentId: this.billsParentIds //父级id
        }
        await this.makeSure(menuData)
        let isAddIndex = 0
        this.billsData.forEach((item, index) => {
          if (item.id == row.id) {
            isAddIndex = index //筛选当前选中项索引
          }
        })
        this.billsData.splice(isAddIndex, 1) //移除已添加的数据
      },

      /* 显示新增修改弹框(type => 0：新增 1:修改) */
      showDialog(type, data) {
        this.operateType = type //操作方式
        if (type == 0) {
          //判断是否有新增权限
          if (this.commonJsExtend.isHaveThePower(22, 10, 2, '新增')) {
            return
          }
          this.title = '新增菜单配置' //修改弹框标题
          if (data) {
            this.parentId = data.id
          } else {
            this.parentId = -1
          }
        } else if (type == 1) {
          //判断是否有修改权限
          if (this.commonJsExtend.isHaveThePower(22, 10, 4, '修改')) {
            return
          }
          this.title = '修改菜单配置' //修改弹框标题
          this.menuConfig = JSON.parse(JSON.stringify(data)) //将选中数据赋值到弹框表单内
          if (this.menuConfig.url && this.menuConfig.paramVal) {
            let paramVal = this.menuConfig.paramVal.replace(/:/g, '=').replace(/@@@/g, '&')
            this.menuConfig.url += '?' + paramVal
          }
          this.menuConfig.isEnable = data.isEnable == 1 ? true : false //是否显示
          this.sourceData = JSON.parse(JSON.stringify(this.menuConfig)) //储存编辑前数据
          this.menuConfig.id = data.id //当前选中id
        }
        this.dialogVisible = true
      },

      /* 关闭新增修改弹框 */
      closeDialog() {
        this.dialogVisible = false //关闭弹框
        // Object.assign(this.$data.menuConfig, this.$options.data().menuConfig)
        this.resetData() //初始化 menuConfig
        if (this.$refs['menuConfigForm']) {
          this.$refs['menuConfigForm'].resetFields(); //清空表单校验
        }
      },

      /* 初始化 menuConfig 数据 */
      resetData() {
        this.menuConfig = {
          id: "",
          name: "",
          shortName: "",
          url: "",
          isEnable: true,
          openType: 0,
          urlType: 1,
        }
      },

      /* 初始化表格行拖动功能*/
      initDrag() {
        const el = document.querySelectorAll("#menu_config_table>.el-table__body-wrapper > table > tbody")[0];
        Sortable.create(el, {
          disabled: false, //启用拖拽
          sort: true, // boolean 定义是否列表单元是否可以在列表容器内进行拖拽排序
          animation: 150, // 定义排序动画的时间
          handle: ".my-handle", //拖动的手柄
          // 结束拖拽
          onEnd: (e) => {
            let data = { //定义传入后端参数
              sourceId: this.tableData[e.oldIndex].id,
              targetId: this.tableData[e.newIndex].id
            }
            //加载loding框
            this.loading = this.commonJsExtend.customLoading("#individuation_menu", 4, '排序更新中,请稍候...');
            //发送请求
            api.updateSort(data).then(res => {
              this.loading.close();
              if (res.code == 200) {
                this.getMenuData(this.curentClassify);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          },
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/.el-input__inner {
    width: 95%;
  }

  .individuation_menu {
    display: flex;
    height: 100%;
    overflow-x: hidden;

    .left_menu {
      height: 100%;
      min-width: 220px;
      overflow: auto;
      padding-right: 7px;
      border-right: 2px #eeeeee solid;

      .menu_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background-color: #f9f5fa;
        }

        .menu_all {
          margin: 14px 0 14px 6px;
          width: -webkit-calc(100% - 16px);
          width: -moz-calc(100% - 16px);
          width: calc(100% - 16px);
        }

        .add_btn {
          display: none;
        }

        &:hover {
          cursor: pointer;

          .add_btn {
            display: inline-block;
          }
        }
      }

      .custom-tree-node {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .add_btn {
          display: none;
        }

        &:hover {
          .add_btn {
            display: inline-block;
          }
        }
      }
    }

    .right_menu {
      width: 88%;
      height: 100%;
      margin-left: 0.5%;

      .menu_table {
        height: 100%;
      }
    }

    .dialog {
      width: 50%;
    }

  }

  .radioGroup {
    padding-top: 7px !important;
  }
</style>
