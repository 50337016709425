<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：附件预览弹框组件
	开始时间：2021-08-18
	开发人员：刘巍骏
	最后修改：2021-08-18
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="dialogPreviewBox">
    <el-dialog :title="fileName" class="dialogPreviewContent" :visible.sync="show_filePreview" :before-close="cancel_proview"
      :fullscreen="true" append-to-body :show-close="false">
      <!-- dialog头部自定义 -->
      <div slot="title" class="dialogPreviewHead">
        <!-- 左边提示信息 -->
        <div class="headLeft">
          <span class="headTitle">{{fileName}}</span>
        </div>

        <!-- 右边按钮选择 -->
        <div class="headRight scollStyle">
          <span class="headSpan" v-show="fileType == 1">
            <el-link class="option" target="_blank" :href="baseFileUrl" :underline="false" :download="fileName">
              <i class="el-icon-download" title="下载">下载</i>
            </el-link>
          </span>
          <span class="headSpan" @click="cancel_proview">关闭</span>
        </div>
      </div>
      <!-- 预览总框 -->
      <div class="previewFileBox" v-if="fileUrl!=-1">
        <iframe :src="fileUrl" class="iframeBox"></iframe>
      </div>
      <div class="previewFileBox" v-else>
        当前文件格式不支持预览
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default {
    name: 'file_preview',
    props: {
      //需要预览的附件路径
      previewFilePath: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        //当前组件数据
        baseFileUrl:'',//原附件路径(未经过wps处理的)
        fileType:0,//附件类型(0:图片类  1:文件类)
        fileUrl: '', //当前预览附件路径
        fileName: '', //当前预览附件名称
      }
    },
    computed: {
      ...mapState({
        show_filePreview: state => state.commComponent.show_filePreview, //控制显示附件预览弹框
      }),
    },
    watch: {
      //监听弹框打开
      show_filePreview(newVal) {
        if (newVal) {
          //处理文件路径
          this.disposeFilePath();
        }
      },
    },
    mounted() {
      //处理文件路径
      this.disposeFilePath();
    },
    methods: {
      ...mapMutations([
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),

      /* 处理文件路径 */
      disposeFilePath() {
        if (this.previewFilePath) {
          //分割信息
          let filePathArr = this.previewFilePath.split("@@@");
          //处理附件路径
          let urlPath = filePathArr[1]; //附件路径
          let urlSuffix = urlPath.split('.').pop().toLowerCase(); //获取附件后缀
          //获取附件名称
          this.fileName = filePathArr[0];
          //处理附件路径
          let noWpsSuffix = ['jpg', 'gif', 'png', 'bmp' ,'pdf']; //不使用wps的文件格式(图片)
          let wpsSuffix = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf','txt']; //使用wps的文件格式(文档)
          //获取原文件地址
          this.baseFileUrl = baseUrl + urlPath;
          // 判断文件格式
          if (noWpsSuffix.includes(urlSuffix)) { //不使用wps的(直接访问)
            this.fileType = 0;//设置附件类型为图片类
            this.fileUrl = baseUrl + urlPath; //附件路径
          } else if (wpsSuffix.includes(urlSuffix)) { //使用wps的(使用第三方访问)
            this.fileType = 1;//设置附件类型为文件类
            console.log(urlPath);
            // this.fileUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=' + urlPath;//附件路径(本地测试用)
            this.fileUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=' + baseUrl + urlPath; //附件路径(服务器使用)
          } else { //未定义的(不支持的格式)
            this.fileType = 1;//设置附件类型为文件类
            this.fileUrl = -1;
          }
        }
      },

      /* 关闭弹框 */
      cancel_proview() {
        this.$emit("SelectedData",true);//发送给父组件的参数,如果有v-if的才需要使用
        this.SHOW_FILEPREVIEW(false);
      },
    }
  }
</script>

<style lang="less">
  .dialogPreviewContent {
    .el-dialog__header{
      padding: 10px;
    }
    .el-dialog__body{
      padding: 0px 10px;
    }
  }
</style>

<style lang="less" scoped="scoped">
  //附件预览总框
  .previewFileBox {
    height: 92vh;
    // border: 1px solid black;
    .iframeBox{
      width:100%;
      height: 100%;
      border: none;
    }
  }

  //附件预览头部自定义
  .dialogPreviewHead{
    padding: 10px;
    background: @form_bgColer;
    display: flex;
    font-size: 16px;
    color: @theme;
    justify-content: space-between;
    // border: 1px solid black;
    .headRight{
      display: flex;
      cursor: pointer;
      span{
        padding: 0 10px;
      }
      a{
        font-size: 16px;
        text-decoration: none;
        color: @theme;
      }
    }
  }
</style>
