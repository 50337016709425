<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据模板页面
	开始时间：2021-01-14
	开发人员：姚雨宏,康泽鑫
	最后修改：<2021-07-07></2021-07-07>
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="setting_itemBox" id="billsTemplateBox">
    <!-- 头部内容 -->
    <div class="setting_headBox">
      <!-- 新增 -->
      <el-button type="primary" size="small" class="button" @click="show_billsModel">新增</el-button>
      <!-- 导出字典 -->
      <el-button type="success" size="small" class="button" @click="showFilePreView(dictionary,1)">查看字典
      </el-button>
      <!-- 提示信息 -->
      <div class="tipsbox">
        <el-tooltip placement="bottom-start">
          <div slot="content">
            <ul>
              <li>1.单据类型新增和修改时编号前缀在同模块下不能有重复的。</li>
              <li>2.单据类型新增和修改时名称在同模块下不能有重复的。</li>
            </ul>
          </div>
          <i class="el-icon-info"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 表格内容 -->
    <div class="AppTreeTableContent">
      <!--左边树形结构 -->
      <div class="AppLeftBox">
        <!-- 分类列表 -->
        <div class="content_left">
          <div class="left_list searchClassfiy">
            <div class="left_all_btn"  @click="getBillsTypeDataAll()">
              <div class="left_title">
                <i class="el-icon-menu"></i>
                <span>全部分类</span>
              </div>
              <div class="add_icon">
                <!-- <i class="el-icon-plus"></i> -->
              </div>
            </div>
            <div>
              <el-tree :data="billsTempTreeData" :props="defaultProps" node-key="id" ref="tree" default-expand-all
                :expand-on-click-node="false" @node-click="getClassifyCostTypeData">
                <span class="custom-tree-node" slot-scope="{ node, billsTempTreeData}">
                  <span :class="{'tree_label':node.parentId == -1}">{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
          </div>
        </div>
      </div>

      <!-- 分类拉伸 -->
      <div class="AppHandelBox"></div>

      <!-- 表格内容 -->
      <div class="AppRightBox">
        <div class="setting_contentBox billing_table">
          <el-table id="classifyTable" :span-method="objectSpanMethod" :data="templeDataEle" height="100%" border
            style="width: 100%">
            <el-table-column prop="temp_name" label="单据模块" fixed width="100"></el-table-column>
            <el-table-column label="操作" width="70" fixed>
              <template slot-scope="scope">
                <i class="el-icon-edit-outline icon" @click="updateBilling(scope.row)" title="编辑"></i>
                <i class="el-icon-delete icon" v-if="scope.row.is_system==0" @click="deleteBilling(scope.row)"
                  title="删除"></i>
              </template>
            </el-table-column>
            <!-- 单据信息 -->
            <el-table-column label="单据基本信息">
              <el-table-column prop="" label="标识" width="60">
                <template slot-scope="scope">
                  <span v-if="scope.row.outin_sign==1" class="span_color_theme">入</span>
                  <span v-else-if="scope.row.outin_sign==0" class="span_color_red">出</span>
                  <span v-else-if ="scope.row.outin_sign==2">无</span>
                </template>
              </el-table-column>
              <el-table-column prop="bills_type" label="单据名称" width="150" :formatter="formatterCellval">
              </el-table-column>
              <el-table-column prop="check_user_name" label="审核人" width="150" :formatter="formatterCellval"
                show-overflow-tooltip></el-table-column>
            </el-table-column>
            <!-- 单据编号格式 -->
            <el-table-column label="单据编号格式">
              <el-table-column prop="prefix" label="前缀" width="100" :formatter="formatterCellval"></el-table-column>
              <el-table-column prop="middle" label="中段格式" width="120" :formatter="formatterCellval"></el-table-column>
              <el-table-column prop="extent" label="序号长度" width="100" :formatter="formatterCellval"></el-table-column>
              <el-table-column prop="separator" label="分隔符" width="80" :formatter="formatterSeparator"></el-table-column>
              <el-table-column label="示例" width="240">
                <template slot-scope="scope">
                  <span>{{getExample(scope.row)}}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 批次号格式 -->
            <!-- <el-table-column label="批次号格式">
              <el-table-column prop="batch_prefix" label="批次号前缀" width="150" :formatter="formatterCellval">
              </el-table-column>
              <el-table-column prop="batch_middle" label="批次号中段格式" width="150" :formatter="formatterCellval">
              </el-table-column>
              <el-table-column prop="batch_extent" label="批次号顺序号长度" width="120" :formatter="formatterCellval">
              </el-table-column>
              <el-table-column prop="batch_separator" label="批次号分隔符" width="150" :formatter="formatterCellval">
              </el-table-column>
            </el-table-column> -->
            <!-- 单据导出打印模板 -->
            <el-table-column label="单据导出打印模板">
              <el-table-column prop="name" label="电子模板" min-width="200">
                <template slot-scope="scope">
                  <div class="operate">
                    <!-- 左边文件名称 -->
                    <div class="operate_left">
                      <span class="span_hover_color_theme" @click="showFilePreView(scope.row)" title="点击预览">{{scope.row.electronic_template_name}}</span>
                    </div>
                    <!-- 右边操作框 -->
                    <div class="operate_right">
                      <!-- <i class="el-icon-view operate_icon" v-if="scope.row.electronic_template_name"
                        @click="showFilePreView(scope.row)" title="预览"></i> -->
                      <i class="el-icon-setting operate_icon" @click="showAddFileBox(scope.row)" title="操作"></i>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!--修改增加弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '模板'" :before-close="cancle_billsModel"
      :visible.sync="billsOrderBox" width="550px" top="10vh" append-to-body>
      <el-tabs v-model="active" :before-leave='judgeStep' id="addBillsTempBox" type="border-card">
        <el-tab-pane label="基本配置" name="0">
          <!-- 弹框内容-->
          <div class="financeDialog addBillsDataBox scollStyle">
            <el-form :rules="bills_rules" class="form" ref="bills_form" :model="save_billsModel" label-width="130px"
              size="mini">
              <el-row>
                <!-- @click.native="showElementMould(save_billsModel.type_id)"-->
                <el-col :span="24">
                  <el-form-item label="单据模块:" prop="type_id">
                    <el-select style="width: 90%" v-model="save_billsModel.type_id" placeholder="请选择单据模块">
                      <el-option v-for="item in NodeClassfiy" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="分类设置:" prop="type_classify">
                    <el-select style="width: 90%" v-model="save_billsModel.type_classify" placeholder="请选择分类设置">
                      <el-option v-for="item in Classification" :key="item.id" :label="item.name" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="出入标识:" prop="resource">
                    <el-radio-group v-model="save_billsModel.outin_sign" style="padding-top: 7px;">
                      <el-radio label="出"></el-radio>
                      <el-radio label="入"></el-radio>
                      <el-radio label="无"></el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 分行显示 -->
              <el-row>
                <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
                <el-col :span="24">
                  <el-form-item label="单据名称:" prop="bills_type">
                    <el-input style="width: 90%;" v-model.trim="save_billsModel.bills_type" placeholder="请输入单据名称">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="编号分隔符:" prop="midpiece_ormat">
                    <!-- 下拉菜单 -->
                    <el-select style="width: 90%" v-model="save_billsModel.CodedSeparator" default-first-option
                      placeholder="请选择编码分隔符">
                      <el-option v-for="(item,index) in separatorData" :key="item.id" :label="item.name"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="编号前缀:" prop="prefix">
                    <el-input style="width: 90%;" v-model.trim="save_billsModel.prefix" placeholder="请输入编号前缀">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="自定义中段格式:" prop="">
                    <el-checkbox v-model="isCustom">是</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item v-if="isCustomMiddle" label="编号中段格式:" prop="custom">
                    <el-input style="width: 90%" v-model="save_billsModel.custom"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item v-if="isMiddle" label="编号中段格式:" prop="midpiece_format">
                    <el-select style="width: 90%" v-model="save_billsModel.midpiece_format" placeholder="请选择中段值信息"
                      @change="getData(0)">
                      <el-option label="yyyyMMdd" value="yyyyMMdd"></el-option>
                      <el-option label="yyMMdd" value="yyMMdd"></el-option>
                      <el-option label="yyyyMM" value="yyyyMM"></el-option>
                      <el-option label="yyyy" value="yyyy"></el-option>
                      <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option> -->
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item v-if="isMiddle" label="顺序号长度:" prop="sequence_len">
                    <el-input style="width: 90%;" oninput="this.value=this.value.replace(/[^1-8]/g,'')"
                      v-model.trim="save_billsModel.sequence_len" placeholder="请输入1-8的数字"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item v-if="isMiddle" label="顺序号规则:" prop="RuleOfserial">
                    <el-select style="width: 90%" v-model="save_billsModel.RuleOfserial">
                      <el-option v-for="item in RuleOfserial" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  <el-row>
                <el-col :span="24">
                  <el-form-item label="电子模板:" prop="electronic_mould">
                    <el-select style="width: 100%" v-model="save_billsModel.electronic_mould" placeholder="请选择电子模板">
                      <el-option v-for="item in electronicMould" :key="item.id" :label="item.name" :value="item.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row> -->
              <el-row>
                <el-col :span="24">
                  <el-form-item label="审核人:">
                    <el-input style="width: 90%;" v-model.trim="save_billsModel.examine"
                      @focus="getExamine(save_billsModel.examineId)" placeholder="请选择审核人"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="状态:">
                    <el-checkbox v-model="save_billsModel.is_enable">启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>

          </div>
        </el-tab-pane>
        <el-tab-pane label="批次号配置" name="1">
          <!-- 弹框内容-->
          <div class="financeDialog addBillsDataBox scollStyle">
            <el-form class="form" :model="batchNumber" :rules="batchRule" ref="batchRef" label-width="130px"
              size="mini">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="是否批次号配置:" prop="">
                    <el-checkbox v-model="is_enable_batch"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-if="is_enable_batch">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="编号分隔符:" prop="midpiece_ormat">
                      <el-select style="width: 90%" v-model="batchNumber.batchCodedSeparator" default-first-option
                        placeholder="请选择编码分隔符">
                        <el-option v-for="(item,index) in separatorData" :key="item.id" :label="item.name"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="编号前缀:" prop="batch_prefix">
                      <el-input style="width: 90%;" v-model.trim="batchNumber.batch_prefix" placeholder="请输入编号前缀">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="自定义中段格式:" prop="">
                      <el-checkbox v-model="is_batch_custom">是</el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item v-if="batchisCustomMiddle" label="编号中段格式:" prop="batch_custom">
                      <el-input style="width: 90%" v-model="batchNumber.batch_custom"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item v-if="batchisMiddle" label="编号中段格式:" prop="batch_midpiece_format">
                      <el-select style="width: 90%" v-model="batchNumber.batch_midpiece_format" placeholder="请选择中段值信息"
                        @change="getData(1)">
                        <el-option label="yyyyMMdd" value="yyyyMMdd"></el-option>
                        <el-option label="yyMMdd" value="yyMMdd"></el-option>
                        <el-option label="yyyyMM" value="yyyyMM"></el-option>
                        <el-option label="yyyy" value="yyyy"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item v-if="batchisMiddle" label="顺序号长度:" prop="batch_sequence_len">
                      <el-input style="width: 90%;" oninput="this.value=this.value.replace(/[^1-8]/g,'')"
                        v-model.trim="batchNumber.batch_sequence_len" placeholder="请输入1-8的数字"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item v-if="batchisMiddle" label="顺序号规则:" prop="batchRuleOfserial">
                      <el-select style="width: 90%" v-model="batchNumber.batchRuleOfserial">
                        <el-option v-for="item in RuleOfserial" :label="item.label" :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="合并规则:" prop="">
                      <el-checkbox v-model="batchNumber.samePrice">相同价格</el-checkbox>
                      <el-checkbox v-model="batchNumber.warehouse" disabled>入库时间</el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancle_billsModel">取 消</el-button>
        <el-button size="small" type="primary" @click="savebills">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 上传导出模板 -->
    <el-dialog title="上传导出模板" :visible.sync="showAddModelBox" width="400px" append-to-body
      :before-close="cancle_uploade">
      <!-- 弹框内容-->
      <div class="financeDialog billAddFileBox scollStyle" id="billsExportBox">
        <!-- 上传新的文件 -->
        <div class="addFileItem">
          <h5>上传新模板<span class="tips">(只能上传500k以内且格式为.xlsx的文件)</span></h5>
          <div class="fileItemContent">
            <el-upload class="upload-demo" :action="fileUpload" :headers="headers" :data="uploadData"
              :before-upload="beforeUplod" :on-success="uploadSuccess" :file-list="fileList" :show-file-list="false">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </div>
        <!-- 从已有中选择 -->
        <div class="addFileItem">
          <h5>从已上传中选择</h5>
          <div class="fileItemContent">
            <el-select v-model="curSelExportModelId" size="small" style="width: 100%;">
              <el-option v-for="item in curExportModel" :key="item.id" :label="item.name" :value="item.id">
                <div style="display: flex;justify-content: space-between;">
                  <div>{{item.name}}</div>
                  <div v-if="curSelExportModelId!=item.id"><i class="el-icon-circle-close fileItemDel"
                      @click.stop="delModels(item)"></i>
                  </div>
                </div>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="ensure_moulde">确定</el-button>
        <el-button size="small" @click="cancle_uploade">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 员工选择组件 -->
    <personnelList @SelectedData="getPersonnelData" :defaultSelected="defaultSelected" v-if=""></personnelList>

    <!-- 附件预览组件 -->
    <filePreview :previewFilePath="previewFilePath"></filePreview>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/individuation'
  //导入公共组件
  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件

  export default {
    name: 'setting_billsModel',
    data() {
      return {
        //年月日
        RuleOfserial: [{
          label: '日',
          value: '1'
        }, {
          label: '月',
          value: '3'
        }, {
          label: '年',
          value: '4'
        }],

        sourceData: {}, //编辑前基本配置数据
        sourceBatchData: {}, //编辑前批次号配置数据
        defaultSelected: [], //默认勾选
        active: '0', //默认选中第一个标签页
        templeDataEle: [], //页面表格数据
        templeDataEleOrigin:[],//界面表格数据源
        billsTempTreeData: [], //单据模板树形结构数据
        showInOrOut: true, //控制出入库标识是否显示

        //增加修改弹窗参数
        operateState: '0', //0：增加 1：修改
        billsOrderBox: false, //默认关闭弹窗
        bills_rules: [], //基本配置表单验证规则
        batchRule: [], //批次号表单验证规则

        dictionary: 'upload//system//importMould//exportDictionary//exportDictionary.xlsx', //字典

        //loading框
        loading: '', //控制显示loading框

        //基本配置
        Classification: [], //分类设置
        isCustom: false, //基本配置是否自定义编码中段格式
        isCustomMiddle: false, //基本配置是否显示自定义编码中段格式输入框
        isMiddle: true, //基本配置是否显示编码中段格式下拉框
        save_billsModel: { //新增单据框里面输入的值
          //单据基本信息
          id: '', //单据id
          type_id: '', //单据模块
          bills_type: '', //单据名称
          is_enable: '', //状态
          outin_sign: '', //出入标识
          old_bills_type: '', //修改之前的单据名称
          type_classify: '', //单据类型分类
          // 单据编号规则
          prefix: '', //前缀
          midpiece_format: '', //编号中段格式
          sequence_len: '', //顺序号长度
          format_str: '', //字符串拼接的前缀，编号中段格式，顺序号长度
          RuleOfserial: '', //顺序号规则
          custom: '', //自定义中段格式内容
          electronic_mould: '', //电子模板
          CodedSeparator: '', //基本配置编码分割
          examine: '', //审核人
          examineId: '', //审核人id
        },

        //批次号配置
        is_enable_batch: false, //是否启用批次号配置
        batchisCustomMiddle: false, //是否批次号显示自定义编码中段格式输入框
        batchisMiddle: true, //是否批次号显示编码中段格式下拉框
        is_batch_custom: false, //是否批次号自定义中段格式
        batchNumber: {
          batch_prefix: '', //前缀
          batch_midpiece_format: '', //编号中段格式
          batch_sequence_len: '', //顺序号长度
          batchRuleOfserial: '', //顺序号规则
          batch_custom: '', // 自定义中段格式
          samePrice: '', //相同价格
          warehouse: '', //入库时间
          batchCodedSeparator: '', //批次号配置编码分割
        },
        batchRuleOfserial: [], //批次号合并规则
        CodeShow: false, //显示编码分割下拉框
        //增加框里面选择单据模块
        electronicMould: [], //增加框里选择电子模板类型

        //单据模板上传相关
        showAddModelBox: false, //控制显示上传模板弹框
        headers: { //上传模板需要用到的token
          token: '',
        },
        uploadData: { //模板上传时需要传的参数
          bill_template_id: '', //单据类型id
          name: '', //单据模板名称
        },
        fileUpload: '', //模板上传接口
        fileList: [], //附件列表
        curExportModel: [], //当前单据类型关联的上传模板
        curSelExportModelId: '', //当前单据类型选择的单据导出模板id
        curBillsData: {}, //当前操作的单据类型数据
        previewFilePath: '', //附件预览路径

        // elementUI相关
        defaultProps: { //改变tree属性名
          children: 'children',
          label: 'name'
        },
      }
    },
    created() {
      //初始化表单验证规则
      this.initRules();
      //初始化界面所需vuex数据
      this.initVuexData();
      //初始化单据模板上传数据
      this.initFileData();
    },
    computed: {
      ...mapState({
        OrderTemplate: state => state.billing.OrderTemplate, //单据模板数据
        BillingTabIndex: state => state.billing.BillingTabIndex, //模板下标
        NodeClassfiy: state => state.system.NodeClassfiy, //节点分类数据
        billingClassifyData: state => state.system.billingClassifyData, //单据模块子级分类数据
        separatorData: state => state.system.separatorData, //系统定义的可使用分隔符
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    mounted() {
      //获取单据模板数据
      this.getelectronicTemplate();
    },
    watch: {
      //监听是否启用批次号
      // is_enable_batch: {
      //   handler(newVal) {
      //     // 判断当前界面操作类型
      //     if(this.operateState == '0'){//新增
      //       if(newVal){
      //         this.batchNumber.samePrice = true
      //       }
      //     }
      //   }
      // },
      /* 监听基本配置是否自定义编码中段格式*/
      isCustom: {
        handler(newVal) {
          if (newVal == true) {
            this.isCustomMiddle = true;
            this.isMiddle = false;
          }
          if (newVal == false) {
            this.isCustomMiddle = false;
            this.isMiddle = true;
          }
        }
      },
      /* 监听批次号是否用自定义中段格式*/
      is_batch_custom: {
        handler(newVal) {
          if (newVal == true) {
            this.batchisCustomMiddle = true;
            this.batchisMiddle = false;
          }
          if (newVal == false) {
            this.batchisCustomMiddle = false;
            this.batchisMiddle = true;
          }
        }
      },
      /* 监听单据模块下拉框选值的改变*/
      'save_billsModel.type_id': {
        handler(newVal) {
          /* 改变分类设置*/
          this.changeClassfy(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'BILLING_ORDERTEMPLATE',
        'BILLS_TEMPLATE_DATA', //所有单据类型模板数据
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),
      ...mapActions([
        'getAllNodeType', //获取节点分类数据
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化当前界面所需vuex数据 */
      initVuexData() {
        // 判断节点模块分类数据是否存在
        if (this.NodeClassfiy.length == 0) {
          this.getAllNodeType();
        }
      },

      /* 改变分类设置*/
      changeClassfy(data) {
        /*this.save_billsModel.type_classify='';//清空下拉分类设置所选的值 */
        //循环获取单据模块子分类
        this.NodeClassfiy.forEach((itemI, indexI) => {
          if (itemI.id == data) {
            this.billingClassifyData.forEach((itemJ, indexJ) => {
              if (itemI.code == itemJ.code) {
                this.Classification = itemJ.data;
              }
            })
          }
        })
      },

      /* 获取所有单据模板数据(树形点击) */
      getBillsTypeDataAll(){
        this.templeDataEle = this.templeDataEleOrigin;
      },

      /* 获取子级单据模板数据(树形点击) */
      getClassifyCostTypeData(data){
        this.templeDataEle = [];
        //判断是否有子级
        if(data.hasOwnProperty('children')){//父级
          data.children.forEach((item,index)=>{
            // 判断级别
            if(data.parentId == -1){
              item.children.forEach((itemJ,indexJ)=>{
                this.templeDataEle.push(itemJ);
              })
            }else if(data.parentId == 0){
              this.templeDataEle.push(item);
            }
          })
        }else{//子级
          this.templeDataEle.push(data);
        }
      },

      /* 点击新增按钮触发*/
      show_billsModel() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 6, 2, '新增')) {
          return
        }
        this.active = '0'; //默认第一个标签页
        this.is_enable_batch = false; //默认不启用批次号配置
        this.operateState = 0;
        this.billsOrderBox = true; //打开弹窗
        this.save_billsModel.CodedSeparator = ''; //清空编号分割
        this.Classification = ''; //清空分类设置
        // 基本配置清空
        this.save_billsModel = {
          bills_type: '', //单据名称
          prefix: '', //前缀
          midpiece_format: '', //编号中段格式
          sequence_len: '', //顺序号长度
          format_str: '', //字符串拼接的前缀，编号中段格式，顺序号长度
          is_enable: true, //状态
          outin_sign: '', //出入标识
          type_id: '', //单据模块
          type_id1: '', //id
          old_bills_type: '', //修改之前的单据名称
          id: '', //单据id
        };
        // 批次号配置清空
        this.batchNumber = {
          batch_prefix: '', //前缀
          batch_midpiece_format: '', //编号中段格式
          batch_sequence_len: '', //顺序号长度
          batchRuleOfserial: '', //顺序号规则
          batch_custom: '', // 自定义中段格式
          samePrice: true, //相同价格
          warehouse: false, //入库时间
        };
      },

      /* 点击编辑按钮触发*/
      updateBilling(data) { //data:chiled data1:全部数据
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 6, 4, '修改')) {
          return
        }
        this.active = '0'; //默认第一个标签页
        this.is_enable_batch = data.is_enable_batch == 1 ? true : false; //默认不启用批次号配置
        this.operateState = 1;
        this.billsOrderBox = true;
        /* 批次号 */
        let batchRuleOfserial = ''; //顺序号规则
        if (data.batch_format != null && data.batch_format != '') {
          // console.log(data.batch_format);
          let batcharray = data.batch_format.split("@@@")[3]; //顺序号规则
          if (batcharray == 1) {
            batchRuleOfserial = '日';
          } else if (batcharray == 2) {
            batchRuleOfserial = '周';
          } else if (batcharray == 3) {
            batchRuleOfserial = '月';
          } else if (batcharray == 4) {
            batchRuleOfserial = '年'
          }
        }
        // 是否自定义中段格式
        if (data.is_batch_custom == 1) {
          if (data.batch_format != null && data.batch_format != '') {
            this.batchNumber.batch_custom = data.batch_format.split("@@@")[1]; //自定义编号中段格式
            this.batchisCustomMiddle = true; //批次号显示自定义编码中段格式输入框
            this.batchisMiddle = false;
            this.batchNumber.batch_prefix = data.batch_format.split("@@@")[0]; //前缀
            this.batchNumber.batch_sequence_len = data.batch_format.split("@@@")[2]; //顺序号长度
          }
        } else if (data.is_batch_custom == 0) {
          if (data.batch_format != null && data.batch_format != '') {
            // console.log("tt");
            this.batchNumber.batch_midpiece_format = data.batch_format.split("@@@")[1]; //编号中段格式
            this.batchNumber.batch_prefix = data.batch_format.split("@@@")[0]; //前缀
            this.batchNumber.batch_sequence_len = data.batch_format.split("@@@")[2]; //顺序号长度
            this.batchisCustomMiddle = false;
            this.batchisMiddle = true;
          }
        }
        /* 基本配置*/
        let RuleOfserial = ''; //顺序号规则
        let array = data.format_str.split("@@@")[3]; //顺序号规则
        if (array == 1) {
          RuleOfserial = '日';
        } else if (array == 2) {
          RuleOfserial = '周';
        } else if (array == 3) {
          RuleOfserial = '月';
        } else if (array == 4) {
          RuleOfserial = '年'
        }
        let outin_sign1 = ''; //出入标识
        if (data.outin_sign == 1) {
          outin_sign1 = "入"
        } else if (data.outin_sign == 0) {
          outin_sign1 = "出"
        } else {
          outin_sign1 = "无"
        }
        /* 基本配置*/
        this.isCustom = data.is_custom == 1 ? true : false; //是否自定义中段格式
        /* 回显分类设置*/
        /*        console.log(this.NodeClassfiy);
                console.log(this.billingClassifyData); */
        /* 循环判断code*/
        let code = ''
        this.NodeClassfiy.forEach((item, index) => {
          if (item.id == data.type_id) {
            code = item.code;
          }
        })
        /* 循环判断得到分类下拉框的值*/
        this.billingClassifyData.forEach((item, index) => {
          if (item.code == code) {
            this.Classification = item.data;
          }
        })
        /* 循环判断得到显示页面的值*/
        this.Classification.forEach((item, index) => {
          if (item.id == data.type_classify) {
            this.save_billsModel.type_classify = item.name;
          }
        })
        let custom = ''
        let midpiece_format = ''
        // 是否自定义中段格式
        if (data.is_custom == 1) {
          custom = data.format_str.split("@@@")[1],
            this.isCustomMiddle = true;
          this.isMiddle = false; //下拉框
        } else if (data.is_custom == 0) {
          midpiece_format = data.format_str.split("@@@")[1]
          this.isCustomMiddle = false;
          this.isMiddle = true;
        }
        this.save_billsModel = {
          RuleOfserial: RuleOfserial, //顺序号规则
          bills_type: data.bills_type, //单据名称
          prefix: data.format_str.split("@@@")[0], //前缀
          sequence_len: data.format_str.split("@@@")[2], //顺序号长度
          format_str: '', //字符串拼接的前缀，编号中段格式，顺序号长度
          is_enable: data.is_enable == 1 ? true : false, //状态
          outin_sign: outin_sign1, //出入标识
          type_id: data.temp_name, //单据模块
          type_id1: data.type_id, //单据模块id
          old_bills_type: data.bills_type, //修改之前的单据名称
          id: data.id, //单据id
          type_classify: this.save_billsModel.type_classify, //分类设置
          CodedSeparator: data.separator, //分隔符
          custom, //自定义中段格式
          midpiece_format, //非自定义中段格式
          examine: data.check_user_name, //审核人名称
          examineId: data.check_user_ids //审核人id
        };

        if (data.check_user_ids) {
          this.defaultSelected = data.check_user_ids.split(',') //审核人默认值
        }

        /* 批次号*/
        this.is_enable_batch = data.is_enable_batch == 1 ? true : false; //回显是否启动批次号
        if (data.is_enable_batch == 1) {
          this.is_batch_custom = data.is_batch_custom == 1 ? true : false; //是否自定义中段格式
          this.batchNumber.batchRuleOfserial = batchRuleOfserial; //顺序号规则
          this.batchNumber.samePrice = data.same_price == 1 ? true : false; //相同价格
          this.batchNumber.warehouse = data.same_add_time == 1 ? true : false; //入库时间
          this.batchNumber.batchCodedSeparator = data.batch_separator; //批次号分隔符
        }
        this.sourceData = JSON.parse(JSON.stringify(Object.assign(this.save_billsModel)))
        this.sourceData.isCustom = this.isCustom
        this.sourceData.is_enable_batch = this.is_enable_batch
        this.sourceData = JSON.parse(JSON.stringify(Object.assign(this.sourceData)))
        this.sourceBatchData = JSON.parse(JSON.stringify(Object.assign(this.batchNumber)))
        // console.log(this.sourceData)
        // console.log(this.batchNumber.batch_prefix);
        // console.log(this.batchNumber.batch_midpiece_format); //编号中段格式);
      },

      /* 查询所有单据类型数据*/
      async getelectronicTemplate(type) {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 6, 1, '查询')) {
            this.templeDataEle = []
            return
          }
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 6, 1, '查询')) {
            this.templeDataEle = []
            return
          }
        }
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#billsTemplateBox", 4, "单据类型信息加载中,请稍候!");
        // 发送请求
        api.getelectronicTemplate().then(res => {
          this.loading.close();
          if (res.code == 200) {
            //将vuex数据更新
            this.BILLS_TEMPLATE_DATA(res.data);
            //给电子单据模板赋值
            this.electronicMould = res.data;
            res.data.forEach(item => {
              this.templeDataEle.push(item.children);
              item.children.forEach(obj => {
                this.electronicMould = obj.electronic_mould; //增加框里选择电子模板类型
                obj.electronic_mould.forEach(objj => {
                  this.electronicMould = objj; //把模板的名字赋值
                })
              })
            })
            let arr1 = []; //存放模板前缀
            let arr2 = []; //存放单据中间字段
            let arr3 = []; //存放单据号顺序号长度
            this.templeDataEle = []
            res.data.forEach(item => {
              item.children.forEach((obj, index) => {
                this.templeDataEle.push(obj)
                /* console.log(obj.format_str); */
                if (obj.format_str != null) {
                  let all = obj.format_str.split("@@@")
                  arr1.push(all[0])
                  arr2.push(all[1])
                  arr3.push(all[2])
                }
              })
            })
            /* 给对象里面赋值temp_name*/
            res.data.forEach(item => {
              this.templeDataEle.forEach((itemJ, index) => {
                if (itemJ.type_id == item.temp_id) {
                  this.$set(this.templeDataEle[index], "temp_name", item.temp_name);
                  this.$set(this.templeDataEle[index], "temp_code", item.temp_code);
                }
              })
            })
            /* 分割templeDataEle里面字符串类内容内容*/
            this.templeDataEle.forEach((itemJ, index) => {
              if (itemJ.batch_format != null) {
                this.$set(this.templeDataEle[index], "batch_prefix", itemJ.batch_format.split("@@@")[0]);
                this.$set(this.templeDataEle[index], "batch_middle", itemJ.batch_format.split("@@@")[1]);
                this.$set(this.templeDataEle[index], "batch_extent", itemJ.batch_format.split("@@@")[2]);
              }
              if (itemJ.format_str != null) {
                this.$set(this.templeDataEle[index], "prefix", itemJ.format_str.split("@@@")[0]);
                this.$set(this.templeDataEle[index], "middle", itemJ.format_str.split("@@@")[1]);
                this.$set(this.templeDataEle[index], "extent", itemJ.format_str.split("@@@")[2]);
              }
            })

            // 获取单据类型树形结构
            this.getBillsTreeData();
            //存一份数据源
            this.templeDataEleOrigin = this.templeDataEle;

            //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
          }
        });
      },

      /* 获取树形结构数据 */
      getBillsTreeData() {
        //拷贝一份分类数据
        let classifyData = JSON.parse(JSON.stringify(this.billingClassifyData));
        //清空树形结构数据
        this.billsTempTreeData = [];
        classifyData.forEach((itemI, indexI) => {
          //获取子级数据
          itemI.children = itemI.data;
          itemI.parentId = -1;//顶级父级id
          itemI.children.forEach((itemJ, indexJ) => {
            itemJ.children = [];
            itemJ.parentId = 0;//二级父级id
            this.templeDataEle.forEach((itemK, indexK) => {
              if (itemI.code == itemK.temp_code && itemJ.value == itemK.type_classify) {
                itemK.name = itemK.bills_type;
                itemJ.children.push(itemK);
              }
            })
          })
        })
        //获取树形结构数据
        this.billsTempTreeData = classifyData;
      },

      /* 删除 */
      deleteBilling(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 6, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#billsTemplateBox", 2);
          //发送请求
          api.deleteBillTemplateByid({
            id: data.id, //选中行id
            name: data.bills_type //选中行名称
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.getelectronicTemplate(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        })
      },

      /* 增加修改单据方法*/
      savebills() {
        let data = ''; //传参
        // 保存前判断是否满足所有表单验证规则
        if (this.operateState == 0) { //新增模板
          let ret = 0; //判断批次号表单验证规则
          if (this.is_enable_batch == true) { //启用批次号配置
            this.$refs['batchRef'].validate(valid => { //验证批次号配置规则
              if (valid) {
                ret = 0
              } else {
                ret = 1
              }
            })
          }
          if (ret == 1) {
            return
          }
          this.$refs['bills_form'].validate(valid => {
            if (valid) {
              //批次号
              let batch_format = ''; //合并之后的字符串
              if (this.is_batch_custom == false) {
                batch_format = this.batchNumber.batch_prefix + "@@@" +
                  this.batchNumber.batch_midpiece_format + "@@@" +
                  this.batchNumber.batch_sequence_len + "@@@" + this.batchNumber.batchRuleOfserial;
              } else if (this.is_batch_custom == true) {
                batch_format = this.batchNumber.batch_prefix + "@@@" +
                  this.batchNumber.batch_custom + "@@@" +
                  this.batchNumber.batch_sequence_len + "@@@" + this.batchNumber.batchRuleOfserial;
              }
              //基本配置
              let format_str = '';
              if (this.isCustom == false) {
                format_str = this.save_billsModel.prefix + "@@@" +
                  this.save_billsModel.midpiece_format + "@@@" +
                  this.save_billsModel.sequence_len + "@@@" + this.save_billsModel.RuleOfserial;
              } else if (this.isCustom == true) {
                format_str = this.save_billsModel.prefix + "@@@" +
                  this.save_billsModel.custom + "@@@" +
                  this.save_billsModel.sequence_len + "@@@" + this.save_billsModel.RuleOfserial;
              }
              var inOR = ''; //出入标识
              if (this.save_billsModel.outin_sign == '入') {
                inOR = 1
              } else if (this.save_billsModel.outin_sign == '出') {
                inOR = 0
              } else {
                inOR = 2 //无
              }
              if (this.is_enable_batch == false) {
                data = {
                  bills_type: this.save_billsModel.bills_type, //单据名称
                  format_str: format_str, //字符串拼接的前缀，编号中段格式，顺序号长度
                  is_enable: this.save_billsModel.is_enable ? 1 : 0, //状态
                  outin_sign: inOR, //出入标识
                  type_id: this.save_billsModel.type_id, //单据模块
                  is_system: '0', //系统默认
                  separator: this.save_billsModel.CodedSeparator, //编号分隔符
                  is_custom: this.isCustom ? 1 : 0, //是否自定义中段格式
                  type_classify: this.save_billsModel.type_classify, //分类设置
                  //批次号
                  is_enable_batch: this.is_enable_batch ? 1 : 0, //是否启用批次号配置
                  check_user_ids: this.save_billsModel.examineId, //审核人id
                  check_user_name: this.save_billsModel.examine //审核人名称
                }
              } else if (this.is_enable_batch == true) {
                data = {
                  bills_type: this.save_billsModel.bills_type, //单据名称
                  format_str: format_str, //字符串拼接的前缀，编号中段格式，顺序号长度
                  is_enable: this.save_billsModel.is_enable ? 1 : 0, //状态
                  outin_sign: inOR, //出入标识
                  type_id: this.save_billsModel.type_id, //单据模块
                  is_system: '0', //系统默认
                  separator: this.save_billsModel.CodedSeparator, //编号分隔符
                  is_custom: this.isCustom ? 1 : 0, //是否自定义中段格式
                  type_classify: this.save_billsModel.type_classify, //分类设置
                  //批次号
                  is_batch_custom: this.is_batch_custom ? 1 : 0, //是否启用批次号配置
                  batch_separator: this.batchNumber.batchCodedSeparator, //批次号分隔符
                  batch_format: batch_format,
                  is_enable_batch: this.batchNumber.is_enable_batch ? 1 : 0, //是否自定义中段格式
                  same_add_time: this.batchNumber.warehouse ? 1 : 0, //相同时间
                  same_price: this.batchNumber.samePrice ? 1 : 0, //相同价格
                  check_user_ids: this.save_billsModel.examineId, //审核人id
                  check_user_name: this.save_billsModel.examine //审核人名称
                }
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#addBillsTempBox", 0);
              //发送请求
              api.addBillTemplate(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.billsOrderBox = false; //关闭弹窗
                  //移除表单验证信息
                  this.$refs['bills_form'].resetFields(); //移除基本配置表单验证规则
                  this.$refs['batchRef'].resetFields(); //移除批次号配置验证批次号配置规则
                  //获取新数组
                  this.getelectronicTemplate(1);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          })
        } else if (this.operateState == 1) { //修改
          let ret = 0; //判断表单验证是否通过
          if (this.is_enable_batch == true) { //启用批次号配置
            this.$refs['batchRef'].validate(valid => { //验证批次号配置规则
              if (valid) {
                ret = 0
              } else {
                ret = 1;
              }
            })
          }
          if (ret == 1)
            return
          this.$refs['bills_form'].validate(valid => {
            if (valid) {
              // console.log("7");
              //批次号
              let batchRule = ''; //批次号顺序规则
              if (this.batchNumber.batchRuleOfserial == '日') {
                batchRule = 1
              } else if (this.batchNumber.batchRuleOfserial == '周') {
                batchRule = 2
              } else if (this.batchNumber.batchRuleOfserial == '月') {
                batchRule = 3
              } else if (this.batchNumber.batchRuleOfserial == '年') {
                batchRule = 4
              } else {
                batchRule = this.batchNumber.batchRuleOfserial
              }
              let batch_format = '';
              if (this.is_batch_custom == false) {
                batch_format = this.batchNumber.batch_prefix + "@@@" +
                  this.batchNumber.batch_midpiece_format + "@@@" +
                  this.batchNumber.batch_sequence_len + "@@@" + batchRule;
              } else if (this.is_batch_custom == true) {
                batch_format = this.batchNumber.batch_prefix + "@@@" +
                  this.batchNumber.batch_custom + "@@@" +
                  this.batchNumber.batch_sequence_len + "@@@" + batchRule;
              }
              //基本配置
              let Rule = ''; //批次号顺序规则
              if (this.save_billsModel.RuleOfserial == '日') {
                Rule = 1
              } else if (this.save_billsModel.RuleOfserial == '周') {
                Rule = 2
              } else if (this.save_billsModel.RuleOfserial == '月') {
                Rule = 3
              } else if (this.save_billsModel.RuleOfserial == '年') {
                Rule = 4
              } else {
                Rule = this.save_billsModel.RuleOfserial;
              }
              let format_str = '';
              if (this.isCustom == false) {
                format_str = this.save_billsModel.prefix + "@@@" +
                  this.save_billsModel.midpiece_format + "@@@" +
                  this.save_billsModel.sequence_len + "@@@" + Rule;
              } else if (this.isCustom == true) {
                format_str = this.save_billsModel.prefix + "@@@" +
                  this.save_billsModel.custom + "@@@" +
                  this.save_billsModel.sequence_len + "@@@" + Rule;
              }
              let outin_sign = ""; //出入标识
              if (this.save_billsModel.outin_sign == '入') {
                outin_sign = 1
              } else if (this.save_billsModel.outin_sign == '出') {
                outin_sign = 0
              } else {
                outin_sign = 2 //无
              }
              /* 循环获取code值*/
              let code = '';
              this.NodeClassfiy.forEach((item, index) => {
                if (item.id == this.save_billsModel.type_id1) {
                  code = item.code;
                }
              })
              /* 通过code值获取信息显示在页面 */
              this.billingClassifyData.forEach((itemI, indexI) => {
                if (code == itemI.code) {
                  itemI.data.forEach((itemJ, indexJ) => {
                    if (itemJ.name == this.save_billsModel.type_classify) {
                      this.save_billsModel.type_classify = itemJ.id;
                    }
                  })
                }
              })
              let logContent = '' //日志内容
              let objFiled = { //定义字典
                RuleOfserial: '基本配置-顺序号规则',
                bills_type: '基本配置-单据名称',
                CodedSeparator: '基本配置-编号分隔符',
                prefix: '基本配置-编号前缀',
                sequence_len: '基本配置-顺序号长度',
                is_enable: '基本配置-状态',
                examine: '基本配置-审核人',
                outin_sign: '基本配置-出入标识',
                type_id: '基本配置-单据模块',
                type_classify: '基本配置-分类设置',
                midpiece_format: '基本配置-编号中段格式',
                custom: '基本配置-编号中段格式',
                batchCodedSeparator: '批次号配置-编号分隔符',
                batch_prefix: '批次号配置-编号前缀',
                is_batch_custom: '批次号配置-自定义中段格式',
                batch_custom: '批次号配置-编号中段格式',
                batch_midpiece_format: '批次号配置-编号中段格式',
                batch_sequence_len: '批次号配置-顺序号长度',
                batchRuleOfserial: '批次号配置-顺序号规则',
                // remark:'说明'
              }
              let keys = Object.keys(objFiled) //获取字段名
              let date = [{ //转换年月日字典
                4: '年',
                3: '月',
                1: '日'
              }]
              if (this.sourceData.isCustom != this.isCustom) { //自定义中段格式
                logContent += '更改了【单据模板】中的【基本配置-自定义中段格式】,更改前:' + (this.sourceData.isCustom ? '是' : '否') +
                  ",更改后:" + (this.isCustom ? '是' : '否') + ';' //拼接日志信息
              }
              if (this.sourceData.is_enable_batch != this.is_enable_batch) { //是否批次号配置
                logContent += '更改了【单据模板】中的【是否批次号配置】,更改前:' + (this.sourceData.is_enable_batch ? '是' : '否') +
                  ",更改后:" + (this.is_enable_batch ? '是' : '否') + ';' //拼接日志信息
              }
              keys.forEach(itemI => {
                //基本配置
                if (this.save_billsModel[itemI] != this.sourceData[itemI]) { //匹配出修改的字段
                  if (itemI == 'type_classify' || itemI == 'type_id') { //分类设置 单据模块
                    let temp = '' //定义空字符串用于赋值
                    let dataArr = {} //定义空对象用于赋值
                    if (itemI == 'type_classify') { //分类设置
                      dataArr = this.Classification
                    } else if (itemI == 'type_id') { //单据模块
                      dataArr = this.NodeClassfiy
                    }
                    dataArr.forEach(itemJ => {
                      if (itemJ.id == this.save_billsModel[itemI]) {
                        temp = itemJ.name //提取出 id 对应的名称
                      }
                    })
                    if (this.sourceData[itemI] != temp) { //当做出修改时
                      logContent += '更改了【单据模板】中的【' + objFiled[itemI] + '】,更改前:' + this.sourceData[itemI] +
                        ",更改后:" + temp + ';' //拼接日志信息
                    }
                  } else if (itemI == 'RuleOfserial') { //顺序号规则
                    logContent += '更改了【单据模板】中的【' + objFiled[itemI] + '】,更改前:' + this.sourceData[itemI] +
                      ",更改后:" + date[0][this.save_billsModel[itemI]] + ';' //拼接日志信息
                  } else if (itemI == 'is_enable') { //状态
                    logContent += '更改了【单据模板】中的【' + objFiled[itemI] + '】,更改前:' + (this.sourceData[itemI] ? '启用' :
                      '不启用') + ",更改后:" + (this.save_billsModel[itemI] ? '启用' : '不启用') + ';' //拼接日志信息
                  } else {
                    logContent += '更改了【单据模板】中的【' + objFiled[itemI] + '】,更改前:' + this.sourceData[itemI] +
                      ",更改后:" + this.save_billsModel[itemI] + ';' //拼接日志信息
                  }
                }
                //批次号
                if (this.batchNumber[itemI] != this.sourceBatchData[itemI]) {
                  if (itemI == 'batchRuleOfserial') { //顺序号规则
                    logContent += '更改了【单据模板】中的【' + objFiled[itemI] + '】,更改前:' + this.sourceBatchData[itemI] +
                      ",更改后:" + date[0][this.batchNumber[itemI]] + ';' //拼接日志信息
                  } else {
                    logContent += '更改了【单据模板】中的【' + objFiled[itemI] + '】,更改前:' + this.sourceBatchData[itemI] +
                      ",更改后:" + this.batchNumber[itemI] + ';' //拼接日志信息
                  }
                }
              })
              let sourceMegre = [] //定义合并规则编辑前数组
              let newMegre = [] //定义合并规则编辑后数组
              if (this.sourceBatchData['samePrice']) { //将符合条件的名称存入合并规则编辑前数组中
                sourceMegre.push('相同价格')
              }
              if (this.sourceBatchData['warehouse']) { //将符合条件的名称存入合并规则编辑前数组中
                sourceMegre.push('入库时间')
              }
              if (this.batchNumber['samePrice']) { //将符合条件的名称存入合并规则编辑后数组中
                newMegre.push('相同价格')
              }
              if (this.batchNumber['warehouse']) { //将符合条件的名称存入合并规则编辑后数组中
                newMegre.push('入库时间')
              }
              if (this.batchNumber['samePrice'] != this.sourceBatchData['samePrice'] || this.batchNumber[
                  'warehouse'] != this.sourceBatchData['warehouse']) { //当合并规则发生改变时
                logContent += '更改了【单据模板】中的【合并规则】,更改前:' + sourceMegre.toString() +
                  ",更改后:" + newMegre.toString() + ';' //拼接日志信息
              }
              let data = ''; //传参
              if (this.is_enable_batch == false) {
                data = {
                  id: this.save_billsModel.id, //单据id
                  new_bills_type: this.save_billsModel.bills_type, //单据名称
                  bills_type: this.save_billsModel.old_bills_type, //单据名称
                  format_str: format_str, //字符串拼接的前缀，编号中段格式，顺序号长度
                  is_enable: this.save_billsModel.is_enable ? 1 : 0, //状态
                  outin_sign: outin_sign, //出入标识
                  type_id: this.save_billsModel.type_id1, //单据模块
                  is_system: '0', //系统默认
                  separator: this.save_billsModel.CodedSeparator, //编号分隔符
                  is_custom: this.isCustom ? 1 : 0, //是否自定义中段格式
                  type_classify: this.save_billsModel.type_classify, //分类设置
                  //批次号
                  is_enable_batch: this.is_enable_batch ? 1 : 0, //是否启用批次号配置
                  check_user_ids: this.save_billsModel.examineId, //审核人id
                  check_user_name: this.save_billsModel.examine, //审核人名称
                  logContent //日志
                }
              } else if (this.is_enable_batch == true) {
                data = {
                  id: this.save_billsModel.id, //单据id
                  new_bills_type: this.save_billsModel.bills_type, //单据名称
                  bills_type: this.save_billsModel.old_bills_type, //单据名称
                  format_str: format_str, //字符串拼接的前缀，编号中段格式，顺序号长度
                  is_enable: this.save_billsModel.is_enable ? 1 : 0, //状态
                  outin_sign: outin_sign, //出入标识
                  type_id: this.save_billsModel.type_id1, //单据模块
                  is_system: '0', //系统默认
                  separator: this.save_billsModel.CodedSeparator, //编号分隔符
                  is_custom: this.isCustom ? 1 : 0, //是否自定义中段格式
                  type_classify: this.save_billsModel.type_classify, //分类设置
                  //批次号
                  is_enable_batch: this.is_enable_batch ? 1 : 0, //是否启用批次号设置
                  batch_separator: this.batchNumber.batchCodedSeparator, //批次号分隔符
                  batch_format: batch_format,
                  is_batch_custom: this.is_batch_custom ? 1 : 0, //是否自定义中段格式
                  same_add_time: this.batchNumber.warehouse ? 1 : 0, //相同时间
                  same_price: this.batchNumber.samePrice ? 1 : 0, //相同价格
                  check_user_ids: this.save_billsModel.examineId, //审核人id
                  check_user_name: this.save_billsModel.examine, //审核人名称
                  logContent //日志
                }
              }

              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#addBillsTempBox", 0);
              //发送请求
              api.updateBillTemplate(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.getelectronicTemplate(2); //获取新数组
                  this.$refs['bills_form'].resetFields(); //移除基本配置表单验证规则
                  this.$refs['batchRef'].resetFields(); //移除批次号配置验证批次号配置规则
                  this.billsOrderBox = false; //关闭弹窗
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          })
        }
      },

      /* 年月日限制 */
      getData(type) {
        var dateType = ''
        //yyyyMM yyyy
        if (type == 0) {
          dateType = this.save_billsModel.midpiece_format
        } else if (type == 1) {
          dateType = this.batchNumber.batch_midpiece_format
        }
        if (dateType == 'yyyyMM') {
          this.RuleOfserial = [{
            label: '月',
            value: '3'
          }, {
            label: '年',
            value: '4'
          }]
        } else if (dateType == 'yyyy') {
          this.RuleOfserial = [{
            label: '年',
            value: '4'
          }]
        } else {
          this.RuleOfserial = [{
            label: '日',
            value: '1'
          }, {
            label: '月',
            value: '3'
          }, {
            label: '年',
            value: '4'
          }]
        }
      },

      /* 无内容显示占位符 */
      formatterCellval(row, column, cellValue, index) {
        if (!Boolean(cellValue)) {
          return "-";
        } else {
          return cellValue;
        }
      },

      /* 空格与无需 */
      formatterSeparator(row, column, cellValue, index) {
        if (!Boolean(cellValue)) {
          return '无'
        } else if (cellValue == " ") {
          return '空格'
        } else {
          return cellValue;
        }
      },

      /*
        单据模板上传相关
      */
      /* 初始话单据模板相关数据 */
      initFileData() {
        //获取token
        this.headers.token = getCookie('token');
        //设置附件上传接口
        this.fileUpload = baseUrl + "/system/ExportMouldUploadEasyExcel";
      },

      /* 删除模板 */
      delModels(data) {
        let delData = {
          id: data.id
        }
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          api.deleteCrmOrderExportMould(delData).then(res => {
            if (res.code == 200) {
              //获取当前单据导出模板数据
              this.getExportAllModelByTypeId(this.curBillsData.id);
              this.$message({
                type: 'success',
                message: '删除成功!',
                duration: this.elDuration
              });
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 显示附件预览弹框 type:1=>单据导出文档 */
      showFilePreView(data, type) {
        if (type == 1) {
          //获取预览路径
          this.previewFilePath = "单据导出文档@@@" + data;
          //显示弹框
          this.SHOW_FILEPREVIEW(true);
        } else {
          let curFileData = {};
          //根据已选模板获取数据
          if (data.electronic_mould) {
            curFileData = data.electronic_mould.filter(item => parseInt(data.electronic_template_id) == item.id)[0];
          }
          //判断有无数据
          if (JSON.stringify(curFileData) != "{}") {
            //获取预览路径
            this.previewFilePath = curFileData.name + "@@@" + curFileData.file_path;
            //显示弹框
            this.SHOW_FILEPREVIEW(true);
          }
        }
      },

      /* 显示上传导出模板弹框 */
      showAddFileBox(data) {
        //根据单据模板获取导出模板数据
        this.getExportAllModelByTypeId(data.id);
        //获取当前单据类型已选单据模板id
        if (data.electronic_template_id) {
          this.curSelExportModelId = parseInt(data.electronic_template_id);
        }
        //获取当前操作的单据类型数据
        this.curBillsData = data;
        //显示弹框
        this.showAddModelBox = true;
      },

      /* 确认当前单据类型选择的的单据模板 */
      ensure_moulde() {
        console.log(this.curBillsData);
        //定义后端接受值
        let updateData = {
          id: this.curBillsData.id, //当前操作的单据模板id
          electronic_template_id: this.curSelExportModelId, //导出模板id
          new_bills_type: this.curBillsData.bills_type, //新单据模板名称
        }
        let curExportModelObj = this.curExportModel.filter(item => parseInt(this.curSelExportModelId) == item.id)[0];
        console.log(curExportModelObj);
        //定义日志内容
        let logContent = '更改了【单据模板】中的【导出模板】,更改前:' + this.curBillsData.electronic_template_name + ",更改后:" +
          curExportModelObj.name + ';';
        updateData.logContent = logContent;
        //发送请求
        api.updateBillTemplate(updateData).then(res => {
          if (res.code == 200) {
            this.cancle_uploade() //关闭弹框
            this.getelectronicTemplate(2); //获取新数组
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 取消电子模板文件上传弹窗*/
      cancle_uploade() {
        this.curSelExportModelId = ''
        this.showAddModelBox = false;
      },

      /* 获取员工弹框选择数据*/
      getPersonnelData(data) {
        let result = this.commonJsExtend.getPersonnelMsg(data);
        //获取选择的id数据
        this.save_billsModel.examineId = result.idStr;
        //获取选择的数据
        this.save_billsModel.examine = result.nameStr;
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 审核人聚焦时显示 */
      getExamine(data) {
        if (data) {
          this.defaultSelected = data.split(',') //审核人默认值
        }
        this.SHOW_PERSONNELBOX(true);
      },

      /* 示例拼接 */
      getExample(data) {
        let random = ''
        //生产随机数
        for (var i = 0; i < data.extent; i++) {
          random += Math.floor(Math.random() * 10)
        }
        //判断是否为自定义中段格式
        if (data.is_custom == 0) { //非自定义中段格式
          return data.prefix + data.separator + this.commonJsExtend.transformDate(data.middle) + data.separator +
            random //拼接示例
        } else if (data.is_custom == 1) { //自定义中段格式
          return data.prefix + data.separator + data.middle + data.separator + random //拼接示例
        }
      },

      /* 根据单据模板id查询导出模板 */
      getExportAllModelByTypeId(id) {
        let data = {
          templateId: id
        }
        api.findByElectronicTemplateId(data).then(res => {
          if (res.code == 200) {
            //获取当前单据类型已上传单据模板数据
            this.curExportModel = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 单据模板上传前执行的方法 */
      beforeUplod(file) {
        //判断文件大小(只能上传500k以内的文件)
        if (file.size / 1024 > 500) {
          this.$message({
            type: 'warning',
            message: '只能上传500k以内的文件!',
            duration: this.elDuration
          })
          //此处必须renturn false;
          return false;
        }
        // 判断文件后缀是否为.xlsx的
        if (file.name.split('.').pop().toLowerCase() != "xlsx") {
          this.$message({
            type: 'warning',
            message: '只能上传.xlsx的文件!',
            duration: this.elDuration
          })
          //此处必须renturn false;
          return false;
        }
        //获取相关参数
        this.uploadData.bill_template_id = this.curBillsData.id
        this.uploadData.name = file.name
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billsExportBox", 4, "导出模板上传中,请稍候!");
      },

      /* 文件上传成功后执行 */
      uploadSuccess(response, file, fileList) {
        this.loading.close();
        if (response.code == 200) {
          this.$message({
            type: 'success',
            message: "导出模板上传成功!",
            duration: this.elDuration
          })
          //获取当前单据导出模板数据
          this.getExportAllModelByTypeId(this.curBillsData.id);
        } else {
          this.$message({
            type: 'error',
            message: response.message,
            duration: this.elDuration
          })
        }
      },

      /* 控制销售和资金不显示批次号*/
      judgeStep() {
        //防止关闭时弹窗时触发
        if (!this.billsOrderBox) return
        let code = ''; //code值
        this.NodeClassfiy.forEach(item => {
          if (item.id == this.save_billsModel.type_id || item.id == this.save_billsModel.type_id1) {
            code = item.code;
          }
        })
        if (code == 'SYS-NODE-TYPE-001' || code == 'SYS-NODE-TYPE-005') {
          this.$message({
            type: 'warning',
            message: '该模块不需设置批次号!',
            duration: this.elDuration
          })
          return false;
        }
        if (this.save_billsModel.type_id == '' || this.save_billsModel.type_id == null) {
          this.$message({
            type: 'warning',
            message: '请先选择单据模块',
            duration: this.elDuration
          })
          return false;
        }
        return true;
      },

      /* elementui表格数据分类方法 */
      objectSpanMethod({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (columnIndex === 0) {
          const _row = (this.getArray(this.templeDataEle).one)[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col
          };
        }
      },

      /* 获取表格里面相同的数据个数放入数组中 */
      getArray() {
        let spanArr = [];
        let ss = [];
        let position = 0;
        this.templeDataEle.forEach((item, index) => {
          if (index === 0) {
            spanArr.push(1);
            position = 0;
          } else {
            if (this.templeDataEle[index].temp_name === this.templeDataEle[index - 1].temp_name) {
              spanArr[position] = spanArr[position] + 1;
              spanArr.push(0);
            } else {
              spanArr.push(1);
              position = index;
            }
          }
        });
        return {
          one: spanArr,
          two: ss
        }
      },

      /* 取消表单验证 */
      cancle_billsModel() {
        this.active = '0';
        this.$refs['bills_form'].resetFields(); //移除基本配置表单验证规则
        this.$refs['batchRef'].resetFields(); //移除批次号配置验证批次号配置规则
        this.billsOrderBox = false;
        this.CodeShow = false; //关闭分隔符下拉框
        this.showInOrOut = true; //显示出入标识
      },

      /* 初始化表单验证规则*/
      initRules() {
        this.batchRule = {
            /* 批次号配置*/
            batch_custom: [{
              required: true,
              message: '请输入自定义中段格式',
              trigger: 'blur'
            }],
            batch_prefix: [{ //顺序号
              required: true,
              message: '批次号前缀不能为空',
              trigger: 'blur'
            }],
            batch_midpiece_format: [{ //中段格式
              required: true,
              message: '中段格式不能为空',
              trigger: 'blur'
            }],
            batchRuleOfserial: [{ //顺序号规则
              required: true,
              message: '顺序号规则不能为空',
              trigger: 'change'
            }],
            batch_sequence_len: [{ //顺序号
                required: true,
                message: '顺序号长度不能为空',
                trigger: 'blur'
              },
              { //规则2
                min: 1, //最小长度
                max: 1, //最大长度
                message: '长度在 1 个字符', //该规则错误提示信息
                trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
              }
            ],
          },
          this.bills_rules = {
            /* 基本配置*/
            custom: [{
              required: true, //是否必填
              message: '请输入自定义中段格式', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }, ],
            bills_type: [ //单据模板规则
              { //规则1
                required: true, //是否必填
                message: '请输入单据名称', //该规则错误信息提示
                trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
              },
              { //规则2
                min: 1, //最小长度
                max: 40, //最大长度
                message: '长度在 1 到 40 个字符', //该规则错误提示信息
                trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
              }
            ],
            type_classify: [ //分类设置
              {
                required: true, //是否必填
                message: '请选择分类设置', //该规则错误信息提示
                trigger: 'change' //哪种事件触发(blur为失去焦点事件)
              },
            ],
            sequence_len: [{ //顺序号
                required: true, //是否必填
                message: '请输入顺序号', //该规则错误信息提示
                trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
              },
              { //规则2
                min: 1, //最小长度
                max: 1, //最大长度
                message: '长度在 1 个字符', //该规则错误提示信息
                trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
              }
            ],
            type_id: [ //单据模板
              {
                required: true,
                message: '请至少选择一个单据模板',
                trigger: 'change'
              }
            ],
            prefix: [{ //前缀
                required: true, //是否必填
                message: '请输入编号编码', //该规则错误信息提示
                trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
              },
              { //规则2
                min: 1, //最小长度
                max: 20, //最大长度
                message: '长度在 1 到 20 个字符', //该规则错误提示信息
                trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
              }
            ],
            midpiece_format: [{ //中段格式
              required: true,
              message: '中段格式不能为空',
              trigger: 'change'
            }],
            RuleOfserial: [{ //顺序号规则
              required: true,
              message: '顺序号规则不能为空',
              trigger: 'change'
            }],

          }
      },
    },
    components: {
      personnelList,
      filePreview
    }
  }
</script>
<style lang="less">
  /* 头部按钮框 */
  .setting_headBox {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    //提示框内容
    .tipsbox {
      margin-left: 10px;
    }
  }

  // 内容高度
  .AppTreeTableContent{
    height: 87vh;
    // border:1px solid black;
    .content_left{
      border-top:1px solid #EBEEF5;
      border-left:1px solid #EBEEF5;
      //label样式
      .tree_label{
        font-weight:bold;
      }
    }
    //表格外框样式
    .setting_contentBox{
      height:100% !important;
      padding-left:10px;
    }
  }

  /* 添加单据模板弹框 */
  .addBillsDataBox {
    height: 45vh;
    overflow: auto;
  }

  //附件模板上传相关
  .addFileItem {

    // border: 1px solid black;
    h5 {
      width: calc(100% - 20px);
      padding: 8px 10px;
      background: @form_bgColer;
      // border: 1px solid black;
    }

    //单个内容
    .fileItemContent {
      padding: 10px;

      // border: 1px solid black;
    }
  }

  // 上传模板图标移入样式
  .fileItemDel:hover {
    color: #409EFF;
  }

  /* 单据模板添加弹框 */
  .billAddFileBox {
    height: 240px;
  }

  // 上传操作按钮
  .operate {
    width: 100%;
    display: flex;
    justify-content: space-between;

    // border: 1px solid black;
    //左边文本内容
    .operate_left {
      // width: 180px;
      width:calc(100% - 40px);
      font-size: 12px;
      // color: @theme;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // border: 1px solid black;
    }

    //右边操作按钮
    .operate_right {
      width: 40px;
      // border:1px solid black;
    }
  }
</style>
