<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：客户编码规则界面
	开始时间：2021-12-07
	开发人员：刘巍骏
	最后修改：2021-12-07
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview" id="customerCodeBox">
    <!-- 统一编码内容 -->
    <div class="customerAllSet billing_table">
      <el-table :data="allSetData" height="100%" border style="width: 100%">
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateClassfiy(scope.row,0)"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column label="分类设置" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="isClassifySet" :disabled="isDisableClassify" @change="isOpenClassifySet">
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="prefix" sortable label="编号前缀" width="120"></el-table-column>
        <el-table-column prop="centre" label="编号中段格式" width="120"></el-table-column>
        <el-table-column prop="extent" label="顺序号长度" width="120"></el-table-column>
        <el-table-column prop="rule" label="顺序号规则" width="120"></el-table-column>
        <el-table-column prop="separator" label="分隔符" width="80"></el-table-column>
        <!-- <el-table-column label="示例" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{getExample(scope.row)}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="sm" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>
    <!-- 分类编码内容 -->
    <div class="customerClassifySet billing_table" v-show="isClassifySet">
      <el-table :data="classifyData" height="100%" border style="width: 100%">
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateClassfiy(scope.row,1)"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" sortable label="客户分类名称" width="200"></el-table-column>
        <el-table-column prop="prefix" label="编号前缀" width="120"></el-table-column>
        <el-table-column prop="centre" label="编号中段格式" width="120"></el-table-column>
        <el-table-column prop="extent" label="顺序号长度" width="120"></el-table-column>
        <el-table-column prop="rule" label="顺序号规则" width="120"></el-table-column>
        <el-table-column prop="separator" label="分隔符" width="80"></el-table-column>
        <!-- <el-table-column label="示例" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{getExample(scope.row)}}</span>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <!-- 编码规则修改弹框 -->
    <el-dialog :title="'设置【'+curUpdateObj.classifyName+'】编码规则'" :visible.sync="show_updateBox" :before-close="cancle_update" width="400px"
      append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :rules="varcode_rules" :model="curUpdateObj" ref="crmclassify_ruleform" label-width="120px" size="mini">
          <el-row>
            <el-col :span="24">
              <el-form-item label="编号前缀:" prop="prefix">
                <el-input v-model="curUpdateObj.prefix" placeholder="请选择前缀"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="自定义中段格式:" prop="">
                <el-checkbox v-model="curUpdateObj.isCustomCentre">是</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="curUpdateObj.isCustomCentre">
            <el-col :span="24">
              <el-form-item label="编号中段格式:" prop="custom">
                <el-input v-model="curUpdateObj.customeCentre" placeholder="请输入中段格式"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="!curUpdateObj.isCustomCentre">
            <el-col :span="24">
              <el-form-item label="编号中段格式:" prop="centre">
                <el-select v-model="curUpdateObj.centre" size="mini" placeholder="请选择中段格式" style="width: 100%;">
                  <el-option v-for="item in midpieceRule" :key="item.id" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="顺序号长度:" prop="extent">
                <el-input oninput="this.value=this.value.replace(/[^0-9]/g,'')" v-model="curUpdateObj.extent"
                  placeholder="请输入顺序号长度">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="分隔符:" prop="separator">
                <el-select v-model="curUpdateObj.separator" default-first-option placeholder="请选择编码分隔符"
                  style="width: 100%;">
                  <el-option v-for="(item,index) in separatorData" :key="item.id" :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="顺序号规则:" prop="rule">
                <el-select v-model="curUpdateObj.rule" style="width: 100%;">
                  <el-option v-for="(item,index) in orderRule" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancle_update">取 消</el-button>
        <el-button size="small" type="primary" @click="updateClassifySet">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入api
  import crmApi from '@/network/system/dictionary'; //数据字典相关方法
  import controlApi from '@/network/system/controls';//控制参数接口

  export default{
    name:'',
    data(){
      return{
        // 参数源数据
        paramSetObj:{},//统一配置参数是否开启
        paramObj:{},//统一配置参数值

        //界面数据
        allSetData:[],//统一编码设置数据
        classifyData:[],//客户分类数据
        updateRuleType:0,//当前修改的客户编码规则类型(0:统一 1:分类)
        curUpdateObj:{//当前修改的数据
          id:'',//修改的数据id
          classifyName:'',//分类名称
          prefix:'',//编号前缀
          isCustomCentre:false,//是否开启自定义中段格式
          customeCentre:'',//自定义中段格式
          centre:'',//中段格式
          extent:'',//顺序号长度
          separator:'',//分隔符
          rule:'',//顺序号规则
        },
        varcode_rules:[],//表单验证规则

        //控制开关
        isDisableClassify:false,//是否禁用分类设置复选框
        isClassifySet:true,//是否启用分类设置
        show_updateBox:false,//控制显示修改弹框

        //辅助数据
        loading:'',//loading框
      }
    },
    computed: {
      ...mapState({
        BillingTabIndex: state => state.billing.BillingTabIndex, //tab界面下标
        separatorData: state => state.system.separatorData, //系统定义的可使用分隔符
        orderRule: state => state.system.orderRule, //系统定义的可使用顺序号规则
        midpieceRule: state => state.system.midpieceRule, //系统定义的可使用中段格式
        userPowerInfo: state => state.user.userPowerInfo, //权限
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      }),
    },
    mounted() {
      console.log(this.companyparamlist);
      console.log(this.companyparamlist.param356);
      //获取统一设置客户分类数据
      this.getSetAllClassifyCodeData();
      //获取客户分类设置数据
      this.getCrmClassifyData();
      //初始化表单验证规则
      this.initFormRule();
    },
    methods:{
      /* 开启分类设置 */
      isOpenClassifySet(){
        this.isClassifySet = true;
      },

      /* 获取统一设置客户分类数据 */
      getSetAllClassifyCodeData(){
        //判断参数是否配置
        if(!this.companyparamlist.param355){
          this.$message({
            type:'error',
            message:"【客户分类统一编码信息是否开启】参数或参数值未配置,请联系管理员!",
            duration:this.elDuration
          })
          return
        }else if(!this.companyparamlist.param356){
          this.$message({
            type:'error',
            message:"【客户分类统一编码规则】参数或参数值未配置,请联系管理员!",
            duration:this.elDuration
          })
          return
        }
        //清空数据
        this.allSetData = [];
        //分割规则信息
        let setAllCodeRule = this.companyparamlist.param356.split("@@@");
        //定义获取的参数值
        let codeItem = {
          prefix:setAllCodeRule[0],//前缀
          centre:setAllCodeRule[1],//中段格式
          extent:setAllCodeRule[2],//顺序号长度
          rule:setAllCodeRule[3],//顺序号规则
          separator:setAllCodeRule[4],//分隔符
          is_custom:setAllCodeRule[5],//是否自定义
        }
        this.allSetData.push(codeItem);

        //获取参数值数据
        let controlData = {
          id:355,//参数值是数据库写死的
        }
        controlApi.findSysParametersValueById(controlData).then(res=>{
          if(res.code == 200){
            this.paramSetObj = res.data;
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })

        //获取是否设置开启分类
        let controlDataset = {
          id:356,//参数值是数据库写死的
        }
        controlApi.findSysParametersValueById(controlDataset).then(res=>{
          if(res.code == 200){
            this.paramObj = res.data;
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })
      },

      /* 获取客户分类数据 */
      getCrmClassifyData(){
        //加载loading框
        // this.loading = ;
        crmApi.customerDict.getCustomerClassfiy().then(res=>{
          if(res.code == 200){
            //获取分类数据
            this.classifyData = [];
            //处理编码数据
            if(res.data.length>0){
              res.data.forEach((item,index)=>{
                let curItem = item;
                //获取编码规则
                if(item.format_str){
                  let itemRule = item.format_str.split("@@@");
                  curItem.prefix = itemRule[0];//前缀
                  curItem.centre = itemRule[1];//中段格式
                  curItem.extent = itemRule[2];//顺序号长度
                  curItem.rule = itemRule[3]; //顺序号规则
                }else{
                  curItem.prefix = "";//前缀
                  curItem.centre = "";//中段格式
                  curItem.extent = "";//顺序号长度
                  curItem.rule = ""; //顺序号规则
                }
                //将处理好的数据存储到data
                this.classifyData.push(curItem);
              })
            }
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })
      },

      /* 显示编辑弹框 */
      updateClassfiy(data,type){
        //获取类型
        this.updateRuleType = type;
        //获取当前修改的数据
        this.curUpdateObj = {
          id:data.id,//修改的数据id
          classifyName:type == 1?data.name:"统一",//分类名称
          prefix:data.prefix,//编号前缀
          isCustomCentre:data.is_custom == 1?true:false,//是否开启自定义中段格式
          customeCentre:data.is_custom == 1?data.centre:'',//自定义中段格式
          centre:data.is_custom == 0?data.centre:'',//中段格式
          extent:data.extent,//顺序号长度
          separator:data.separator,//分隔符
          rule:data.rule,//顺序号规则
        }
        //显示修改弹框
        this.show_updateBox = true;
      },

      /* 修改分类设置的编码 */
      updateClassifySet(){
        //验证表单
        this.$refs['crmclassify_ruleform'].validate(valid => {
          if(valid){
            //处理编码规则
            let format_str = "";
            //判断是否自定义中段格式
            if(this.curUpdateObj.isCustomCentre){//是
              format_str = this.curUpdateObj.prefix+"@@@"+this.curUpdateObj.customeCentre+"@@@"+this.curUpdateObj.extent+"@@@"+this.curUpdateObj.rule;
            }else{//否
              format_str = this.curUpdateObj.prefix+"@@@"+this.curUpdateObj.centre+"@@@"+this.curUpdateObj.extent+"@@@"+this.curUpdateObj.rule;
            }
            //加载loading框
            //判断修改的是全部还是分类
            if(this.updateRuleType == 0){//统一设置
              //是否自定义中段格式
              let isCustomer = this.curUpdateObj.isCustomCentre==true?1:0;
              let oldVal = JSON.parse(JSON.stringify(this.paramObj.para_value));
              //获取参数值
              this.paramObj.para_value = format_str + "@@@" + this.curUpdateObj.separator + "@@@" + isCustomer;
              //获取日志
              this.paramObj.logContent = "修改了【客户统一编码规则】,修改前:"+oldVal+",修改后:"+this.paramObj.para_value;
              // 定义后端接收参数
              controlApi.updateSysParametersValue(this.paramObj).then(res=>{
                if(res.code == 200){
                  //关闭弹框
                  this.show_updateBox = false;
                  //提示信息
                  this.$message({
                    type:'success',
                    message:"【"+this.curUpdateObj.classifyName+"】编码修改成功!",
                    duration:this.elDuration
                  })
                  //获取新的客户数据
                  this.getSetAllClassifyCodeData();
                }else{
                  this.$message({
                    type:'error',
                    message:res.message,
                    duration:this.elDuration
                  })
                }
              })
            }else if(this.updateRuleType == 1){//分类设置
              //定义后端接受参数
              let updateClassifyData = {
                classifyid:this.curUpdateObj.id,//分类id
                separator:this.curUpdateObj.separator,//分隔符
                format_str:format_str,//编码规则
                is_custom:this.curUpdateObj.isCustomCentre==true?1:0,//是否自定义
                logContent:'',//日志内容
              }
              // this.loading = "";
              crmApi.customerDict.updateCrmClassifyCodeRule(updateClassifyData).then(res=>{
                if(res.code == 200){
                  //关闭弹框
                  this.show_updateBox = false;
                  //提示信息
                  this.$message({
                    type:'success',
                    message:"【"+this.curUpdateObj.classifyName+"】编码修改成功!",
                    duration:this.elDuration
                  })
                  //获取新的客户数据
                  this.getCrmClassifyData();
                }else{
                  this.$message({
                    type:'error',
                    message:res.message,
                    duration:this.elDuration
                  })
                }
              })
            }
          }
        })
      },

      /* 初始化表单验证规则 */
      initFormRule(){
        //第一个修改框验证规则
        this.varcode_rules = {
          prefix: [{ //前缀
            required: true, //是否必填
            message: '请输入编号前缀', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, { //规则2
            min: 1, //最小长度
            max: 10, //最大长度
            message: '长度在 1 到 10 个字符', //该规则错误提示信息
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }],
          /* centre: [{ //中段格式
             required: true,
             message: '请至少选择一个中段格式',
             trigger: 'change'
           }], */
          rule: [{ //顺序号规则
            required: true,
            message: '请选择一个顺序号规则',
            trigger: 'blur'
          }, ],
          extent: [{ //顺序号长度
              required: true, //是否必填
              message: '请输入顺序号长度', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 1, //最大长度
              message: '长度只能为个位数', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 取消修改弹框 */
      cancle_update(){
        this.show_updateBox = false;
      },
    }
  }
</script>

<style lang="less">
  //统一分类设置
  .customerAllSet{
    height: 100px;
    // border: 1px solid black;
  }

  //分类编码设置
  .customerClassifySet{
    margin-top: 10px;
    height: calc(100% - 110px);
    overflow: auto;
    // border: 1px solid black;
  }
</style>
